import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import * as Store from '../store/slices'
import { BaseGrid, BasePage, Card } from '../components/layout'
import { GridColDef } from '@mui/x-data-grid'
import { BaseButton } from '../components/buttons'
import { BaseDialog } from '../components/dialogs'
import { Product, ProductFormActions } from '../types'
import { ProductForm } from '../components/forms'
import { ApiUtility, Endpoints, FormattingUtility, XlUtility } from '../utilities'
import { BaseInput, ComboBox, FileDrop } from '../components'

const ProductColumnDefinitions: GridColDef[] = [
    { field: 'sku', headerName: 'SKU', flex: 1, },
    { field: 'description', headerName: 'Description', flex: 2, renderCell: ({ row }) => row.description.substring(0, 50) },
    { field: 'dimensions', headerName: 'Dimensions', flex: 2, renderCell: ({ row }) => FormattingUtility.formatProductDimensions(row) },
]

export default function Page() {
    const dispatch = useAppDispatch()
    const currentUser = useAppSelector(Store.getCurrentUser)
    const products = useAppSelector<Product[]>(Store.getProducts)
    const [filteredProducts, setFilteredProducts] = useState<Product[]>(products)
    const [isProductDialogOpen, toggleProductDialog] = useState<boolean>(false)
    const [selectedProductId, setSelectedProductId] = useState<number | undefined>()
    const [isImportDialogOpen, setIsImportDialogOpen] = useState<boolean>(false)
    const [isImportConfirmationOpen, setIsImportConfirmationOpen] = useState<boolean>(false)
    const [importFile, setImportFile] = useState<File | null>()
    const [statusText, setStatusText] = useState<string>()

    const onSearchChanged = async (value: string | null) => {
        if (!value) {
            await resetList()
            return
        }

        const response = await ApiUtility.Get(Endpoints.Products + `?search=${value}&include-inactive=true`)
        if (response.success && response.data.length > 0) {
            setFilteredProducts(response.data)
            dispatch(Store.setProducts(response.data))
        } else {
            setFilteredProducts([])
            // alert('Unable to find products matching the search')
        }
    }

    const resetList = async () => {
        const response = await ApiUtility.Get(Endpoints.Products)
        if (response.success) {
            dispatch(Store.setProducts(response.data))
        }
    }
    const onSubmit = async (action: ProductFormActions, product: Product) => {
        // Close the dialog and unselect the product no matter what
        toggleProductDialog(false)
        setSelectedProductId(0)

        /* Note:
            cancelled: Nothing else to do, return early
            deleted: Remove from store and state, return early
            created: Re-perform any active searches
            edited: Re-perform any active searched
        */
        switch (action) {
            case 'cancelled': {
                return
            }
            case 'deleted': {
                // Remove deleted product from page's filtered list
                let list = filteredProducts.filter(p => p.idProducts !== product.idProducts)
                setFilteredProducts(list)

                // Remove deleted product from app's store
                dispatch(Store.setProducts(products.filter(p => p.idProducts !== product.idProducts)))
                return
            }
            case 'created':
            case 'edited':
            default: {
                break
            }
        }

        // Update cache for app store
        const response = await ApiUtility.Get(Endpoints.Products)
        if (response.success) {
            dispatch(Store.setProducts(response.data))
            setFilteredProducts(response.data)
        }
    }

    const onFilesReceived = (files: File[]): void => {
        if (files.length === 0) {
            return
        }
        if (files.length > 1) {
            alert('This function can only accept 1 file.')
            return
        }
        
        setImportFile(files[0])
        setIsImportDialogOpen(false)
        setIsImportConfirmationOpen(true)
    }

    const importConfirmed = async () => {
        // 0. Validate
        if (!importFile) {
            alert('No import file loaded.')
            return
        }

        // 0. Parse
        let utility = new XlUtility()
        let products = await utility.getProducts(importFile)
        console.log(`Starting import of ${products.length} SKUs`)

        // 0. Upload
        let failed = []
        for (let i = 0; i < products.length; i++) {
            let result = await ApiUtility.Put(Endpoints.Products, products[i])
            if (result.success) {
            } else {
                failed.push(products[i])
            }
            
            let msg = `Processed ${i+1}/${products.length} products.`
            console.log(msg)
            setStatusText(msg)
        }

        console.error('Failed imports', failed)
        let res = `Done! There were/was ${failed.length} failed import(s).`
        alert(res)

        // 0. Reset for next time (lol)
        setStatusText('')
        setImportFile(null)
        setIsImportConfirmationOpen(false)
    }

    return (
        <BasePage title='Catalog' requireAuth={true}>
            <div className='ui-row' style={{ justifyContent: 'space-between' }}>
                <p className='wizard-instructions'>This page is for managing SKUs, aka Products.</p>
                <Stack direction='row' spacing={1}>
                    <BaseInput placeholder='Search Products' type='search' onChange={(e) => onSearchChanged(e.target.value)} fullWidth />
                    {/* <ComboBox name='search' defaultValue='ads' placeholder='Search Products' onSelect={(item) => onSearchChanged(item?.label || null)} items={products.map((product) => { return { key: product.uuid, label: product.sku } })} /> */}
                </Stack>
            </div>
            <div className='ui-row'>
                <Card title='SKUs' fullWidth rightHeader={
                    <BaseButton text='Add New' leftIcon='add' onClick={() => toggleProductDialog(true)} style={{ height: 32, padding: 8, marginBottom: 8 }} />
                }>
                    {filteredProducts.length > 0 && (
                        <BaseGrid
                            columns={ProductColumnDefinitions}
                            data={filteredProducts}
                            idKey='idProducts'
                            maxHeight='450px'
                            onRowClick={({ row }) => { setSelectedProductId(row.idProducts); toggleProductDialog(true) }}
                        />
                    )}
                </Card>
            </div>
            <BaseDialog
                isOpen={isProductDialogOpen}
                cancellable={true}
                onClose={() => { toggleProductDialog(false); setSelectedProductId(0) }}
                heading={!!selectedProductId ? 'Edit Product' : 'Add Product'}
            >
                <Box minWidth={500} />
                <Stack spacing={2}>
                    <ProductForm productId={selectedProductId} onSubmit={onSubmit} />
                </Stack>
            </BaseDialog>
            <BaseDialog
                isOpen={isImportDialogOpen}
                cancellable={true}
                onClose={() => setIsImportDialogOpen(false)}
                heading='Import Products'
            >
                <Box minWidth={500} />
                <Stack spacing={2}>
                    <FileDrop name='sku_import' instructions='default' fullHeight accept='.xlsx' filesReceived={onFilesReceived} />
                </Stack>
            </BaseDialog>
            <BaseDialog
                isOpen={isImportConfirmationOpen}
                cancellable={true}
                onClose={() => setIsImportConfirmationOpen(false)}
                heading='Confirm Import'
            >
                <Stack spacing={2}>
                    <Typography>Are you sure that you want to import a bunch of products into the system? Duplicates will be allowed, so this could cause a big mess.</Typography>
                    {!!statusText && (
                        <Stack direction='row' spacing={1} alignItems='center'>
                            <CircularProgress size='1em' />
                            <Typography ><strong>{statusText}</strong></Typography>
                        </Stack>
                    )}
                    <Stack direction='row' spacing={2}>
                        <BaseButton text='Cancel' disabled={!!statusText} variant='delete' leftIcon='x' onClick={() => { setImportFile(null); setIsImportConfirmationOpen(false); }} />
                        <BaseButton text='Confirm' disabled={!!statusText} leftIcon='send' onClick={importConfirmed} />
                    </Stack>
                </Stack>
            </BaseDialog>
        </BasePage>
    );
}