import React from 'react'

interface LinkButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    onClick: any
    text: string
}

const LinkButton: React.FC<LinkButtonProps> = (props) => {
    return (
        <div>
            <p style={{ textDecoration: 'underline', height: '100%', display: 'flex', alignItems: 'center' }} onClick={props.onClick}>
                {props.text}
            </p>
        </div>
    )
}

export default LinkButton;