import { Amplify, Auth } from 'aws-amplify'

interface AWSAuthResponse {
    challengeName: 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA' | 'NEW_PASSWORD_REQUIRED' | 'MFA_SETUP' | null
    challengeParam: any
    requiredAttributes: string[]
}

interface AuthResponse {
    signedIn: boolean
    invalidLogin: boolean
    promptMfa: boolean
    promptNewPassword: boolean
    unhandledResponse: boolean
}

let CognitoUser: AWSAuthResponse

const AuthUtility = {
    Initialize: async (): Promise<void> => {
        Amplify.configure({
            Auth: {
                mandatorySignIn: true,
                region: process.env.REACT_APP_AWS_REGION,
                userPoolId: process.env.REACT_APP_COGNITO_POOL,
                userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT,
                identityPoolId: process.env.REACT_APP_IDENTITY_POOL,
            },
            Storage: {
                AWSS3: {
                    bucket: process.env.REACT_APP_S3_BUCKET_NAME,
                    region: process.env.REACT_APP_S3_REGION,
                },
            }
        })
    },
    IsSignedIn: async (): Promise<boolean> => {
        try{
            let session = await AuthUtility.GetCurrentUser()
            return !!session
        } catch (e: any) {
            return false
        }
    },
    SignIn: async (username: string, password: string): Promise<AuthResponse> => {
        try {
            CognitoUser = await Auth.signIn(username, password)

            switch (CognitoUser.challengeName) {
                case 'SMS_MFA':
                case 'SOFTWARE_TOKEN_MFA':
                    return {
                        signedIn: false,
                        invalidLogin: false,
                        promptMfa: true,
                        promptNewPassword: false,
                        unhandledResponse: false,
                    }
                case 'NEW_PASSWORD_REQUIRED':
                    return {
                        signedIn: false,
                        invalidLogin: false,
                        promptMfa: false,
                        promptNewPassword: true,
                        unhandledResponse: false,
                    }
                case 'MFA_SETUP':
                    alert('AuthUtility - mfa setup required') //User hasn't finished setting up TOTP method

                    return {
                        signedIn: false,
                        invalidLogin: false,
                        promptMfa: false,
                        promptNewPassword: false,
                        unhandledResponse: true,
                    }
                default:
                    break
            }

            return {
                signedIn: true,
                invalidLogin: false,
                promptMfa: false,
                promptNewPassword: false,
                unhandledResponse: false,
            }
        } catch (err: any) {
            if (err.code === 'UserNotConfirmedException') {
                await Auth.resendSignUp(username) //User hasn't finished the sign up process
            } else if (err.code === 'PasswordResetRequiredException') {
                //Note: Using the forgot password flow gets around this scenario.
                return {
                    signedIn: false,
                    invalidLogin: false,
                    promptMfa: false,
                    promptNewPassword: true,
                    unhandledResponse: false,
                }
            } else if (err.code === 'NotAuthorizedException') {
                return {
                    signedIn: false,
                    invalidLogin: true,
                    promptMfa: false,
                    promptNewPassword: false,
                    unhandledResponse: false,
                }
            } else if (err.code === 'UserNotFoundException') {
                // Email doesn't exist in the Cognito user pool
            } else if (err.code === "InvalidLambdaResponseException") {
            } else {
                //Non-AWS Exception
            }

            return {
                signedIn: false,
                invalidLogin: false,
                promptMfa: false,
                promptNewPassword: false,
                unhandledResponse: true,
            }
        }
    },
    ConfirmSignIn: async (mfaCode: string): Promise<AuthResponse> => {
        try {
            await Auth.confirmSignIn(CognitoUser, mfaCode, 'SMS_MFA')

            return {
                signedIn: true,
                invalidLogin: false,
                promptMfa: false,
                promptNewPassword: false,
                unhandledResponse: false,
            }
        } catch (error: any) {
            return {
                signedIn: false,
                invalidLogin: false,
                promptMfa: false,
                promptNewPassword: false,
                unhandledResponse: !error.code,
            }
        }
    },
    CompleteNewPassword: async (newPassword: string): Promise<AuthResponse> => {
        try {
            let response = await Auth.completeNewPassword(CognitoUser, newPassword)
            let promptMfa = response.challengeName === 'SMS_MFA'

            return {
                signedIn: !promptMfa,
                invalidLogin: false,
                promptMfa: promptMfa,
                promptNewPassword: false,
                unhandledResponse: false,
            }
        } catch (error: any) {
            return {
                signedIn: false,
                invalidLogin: false,
                promptMfa: false,
                promptNewPassword: false,
                unhandledResponse: true,
            }
        }
    },
    ForgotPassword: async (email: string): Promise<boolean> => {
        return await Auth.forgotPassword(email)
    },
    ForgotPasswordConfirm: async (email: string, code: string, newPassword: string): Promise<boolean> => {
        try {
            await Auth.forgotPasswordSubmit(email, code, newPassword)
            return true
        } catch (error) {
            return false
        }
    },
    SignOut: async (): Promise<void> => {
        await Auth.signOut()
    },
    ToggleMfa: async (phoneNumber: string, enable: boolean): Promise<boolean> => {
        try {
            let user = await AuthUtility.GetCurrentUser()
            let updateAttrResponse = await Auth.updateUserAttributes(user, { phone_number: phoneNumber })
            if (updateAttrResponse !== 'SUCCESS') {
                return false
            }

            let setMFAResponse = await Auth.setPreferredMFA(user, enable ? 'SMS' : 'NOMFA')
            return setMFAResponse === 'SUCCESS'
        } catch (error: any) {
            return false
        }
    },
    RememberDevice: async (): Promise<void> => {
        try {
            await AuthUtility.GetCurrentUser()
            await Auth.rememberDevice()
        } catch (err: any) {
            return
        } 
    },
    ResendPhoneVerification: async (): Promise<boolean> => {
        try {
            await Auth.verifyCurrentUserAttribute('phone_number')
            return true
        } catch (error) {
            return false
        }
    },
    ChangePhone: async (newPhone: string): Promise<{ success: boolean, errorCode?: string }> => {
        try {
            let user = await AuthUtility.GetCurrentUser()
            await Auth.updateUserAttributes(user, { phone_number: newPhone })
            await Auth.verifyCurrentUserAttribute('phone_number')
            return { success: true }
        } catch (error: any) {
            if (error.code) {
                return { success: false, errorCode: error.code }
            }
            return { success: false, errorCode: 'UnhandledException' }
        }
    },
    VerifyPhone: async (authCode: string): Promise<boolean> => {
        try {
            let user = await AuthUtility.GetCurrentUser()
            let response = await Auth.verifyUserAttributeSubmit(user, 'phone_number', authCode)

            return response === 'SUCCESS'
        } catch (error) {
            return false
        }
    },
    ChangeEmail: async (newEmail: string): Promise<{ success: boolean, errorCode?: string }> => {
        try {
            let user = await AuthUtility.GetCurrentUser()
            await Auth.updateUserAttributes(user, { email: newEmail })
            await Auth.verifyCurrentUserAttribute('email')
            return { success: true }
        } catch (error: any) {
            if (error.code) {
                return { success: false, errorCode: error.code }
            }
            return { success: false, errorCode: 'UnhandledException' }
        }
    },
    VerifyEmail: async (code: string): Promise<{ success: boolean, errorCode?: string }> => {
        try {
            await Auth.verifyCurrentUserAttributeSubmit('email', code)
            return { success: true }
        } catch (error: any) {
            if (error.code) {
                return { success: false, errorCode: error.code }
            }
            return { success: false, errorCode: 'UnhandledException' }
        }
    },
    ChangePassword: async (oldPassword: string, newPassword: string): Promise<{ success: boolean, errorCode?: string }> => {
        try {
            let user = await AuthUtility.GetCurrentUser()
            await Auth.changePassword(user, oldPassword, newPassword)

            return { success: true }
        } catch (error: any) {
            if (error.code) {
                return { success: false, errorCode: error.code }
            }

            return { success: false, errorCode: 'UnhandledException' }
        }
    },
    GetCurrentUser: async () => {
        return await Auth.currentAuthenticatedUser()
    },
    GetAuthToken: async (): Promise<string> => {
        let session = await Auth.currentSession()
        return session.getAccessToken().getJwtToken()
    },
    RefreshAuth: async (): Promise<void> => {
        // Auth.currentSession() automatically attempts to refresh the session when access token is expired. 
        // https://docs.amplify.aws/lib/auth/manageusers/q/platform/js#retrieve-current-session
        await Auth.currentSession()
    },
}

export default AuthUtility