import { useEffect, useState } from 'react'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { DropDownItem } from '../../types'
import { RequiredIndicator } from '../ui'

interface DropdownProps {
    defaultValue?: string
    disabled?: boolean
    error?: string | boolean
    fullWidth?: boolean
    items: DropDownItem[]
    itemPlaceholder?: string
    label?: string
    labelClassName?: string
    labelColor?: string
    name?: string
    onSelect?: (selected: string, item: DropDownItem) => void
    optional?: boolean
    placeholder?: string
    textPrefix?: string
}

function Dropdown(props: DropdownProps) {
    const [selected, setSelection] = useState<string>(props.defaultValue || '')
    const label = !!props.label ? props.label + (props.error ? ': ' + props.error : ''): false

    const handleChange = (event: SelectChangeEvent) => {
        event.stopPropagation()
        let val = event.target.value as string
        setSelection(val)
        if (props.onSelect) {
            let item = props.items.filter(el => el.label === val)[0]
            props.onSelect(item.label, item)
        }
    }

    useEffect(() => {
        if (props.defaultValue !== selected) {
            setSelection(props.defaultValue || '')
        }
    }, [props.defaultValue])

    return (
        <div style={{ display: 'block', marginBottom: 15, width: props.fullWidth ? '100%' : '194px' }}>
            {!!label && (
                <div className='input-label' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                    <p className='input-label' style={{ color: props.error ? '#FF0000' : 'var(--app-text-dark)' }}>{label}{!props.optional && (<RequiredIndicator />)}</p>
                    {props.optional && <p className='subtle'>Optional</p>}
                </div>
            )}
            <Select
                defaultValue={selected}
                disabled={props.disabled}
                disableUnderline={true}
                placeholder={props.placeholder}
                margin='dense'
                onClick={e => e.stopPropagation()}
                onChange={handleChange}
                style={{
                    width: props.fullWidth ? '100%' : '194px',
                    height: '2.5em',
                    border: '1px solid var(--ui-light-blue)',
                    backgroundColor: '#ffffff',
                    borderRadius: '0.5em',
                    paddingLeft: 10
                }}
                value={selected}
                variant='standard'
            >
                {
                    props.items?.length > 0
                        ? props.items.map(el => (
                            <MenuItem key={el.label} value={el.label}>{el.label}</MenuItem>
                        ))
                        : <MenuItem disabled key='' value=''>{props.itemPlaceholder}</MenuItem>
                }
            </Select>
        </div>
    )
}

export default Dropdown
export type { DropDownItem }
