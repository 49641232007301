import { BaseButton } from '../buttons'
import { Formik } from 'formik'
import { BaseInput, PhoneInput } from '../inputs'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import * as Store from '../../store/slices'
import { Location } from '../../types'
import { ApiUtility, Endpoints, FormattingUtility } from '../../utilities'
import * as Yup from 'yup'
import { LocationSchema } from '../../types/schemas'

type EditLocationFormProps = {
    location: Location
    onSubmit: () => void
}

export default function Form(props: EditLocationFormProps) {
    const dispatch = useAppDispatch()
    const dealerships = useAppSelector(Store.getDealerships)

    const submit = async (values: any) => {
        let dealership = dealerships.find(el => el.idDealerships === props.location.idDealerships)
        if (!dealership) {
            alert('Dealerships are not correctly loaded in state! Sign out and sign back in to try again. If this issue persists, please contact Support using the Report a Problem page.')
            return
        }

        let response = await ApiUtility.Post(Endpoints.Locations, values)

        if (!response.success) {
            alert('There was an unknown error while trying to update this Location. Please try again.')
            return
        }

        // Add updated location to dealership's locations list
        let locations = []
        for (let location of dealership.locations) {
            if (location.idLocations === props.location.idLocations) {
                locations.push(response.data)
            } else {
                locations.push(location)
            }
        }

        // Update dealership locally
        let list = []
        for (let dealership of dealerships) {
            let d = {...dealership}
            if (d.idDealerships === props.location.idDealerships) {
                d.locations = locations
            }

            list.push(d)
        }

        dispatch(Store.setDealerships(list))

        // Notify parent of submit succeeded
        props.onSubmit()
    }

    return (
        <Formik
            initialValues={props.location}
            validationSchema={LocationSchema}
            onSubmit={submit}
        >
            {({ values, errors, touched, dirty, isValid, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <BaseInput name='name' label='Location Name' value={values.name} error={touched.name && errors.name ? errors.name : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                    <PhoneInput name='phoneNumber' label='Phone Number' defaultValue={values.phoneNumber} error={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                    <PhoneInput name='faxNumber' label='Fax Number' optional defaultValue={values.faxNumber} error={touched.faxNumber && errors.faxNumber ? errors.faxNumber : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                    <div style={{ width: '100%', height: '2px', backgroundColor: 'var(--ui-dark-grey)', margin: '2em 0em' }}></div>
                    <BaseInput name='address1' label='Address 1' value={values.address1} error={touched.address1 && errors.address1 ? errors.address1 : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                    <BaseInput name='address2' label='Address 2' optional value={values.address2} error={touched.address2 && errors.address2 ? errors.address2 : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                    <BaseInput name='city' label='City' value={values.city} error={touched.city && errors.city ? errors.city : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                    <BaseInput name='state' label='State' value={values.state} error={touched.state && errors.state ? errors.state : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                    <BaseInput name='zip' label='Zip' value={values.zip} error={touched.zip && errors.zip ? errors.zip : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <BaseButton text='Update' disabled={!isValid || !dirty} onClick={handleSubmit} type='submit' rightIcon='arrowRight' loading={isSubmitting} />
                    </div>
                </form>
            )}
        </Formik>
    )
}