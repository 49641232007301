import { useState } from 'react'
import { ClickAwayListener, IconButton, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { InfoOutlined } from '@mui/icons-material'

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[4],
        fontSize: '1em',
        padding: '1em',
    },
}));

export default function (props: { text: string }) {
    const [open, toggle] = useState<boolean>(false)

    return (
        <ClickAwayListener onClickAway={() => toggle(false)}>
            <div>
                <StyledTooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={() => toggle(false)}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={props.text}
                >
                    <IconButton onClick={() => toggle(!open)}>
                        <InfoOutlined fontSize='small' />
                    </IconButton>
                </StyledTooltip>
            </div>
        </ClickAwayListener>
    )
}