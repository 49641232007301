import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { MessageRow } from '.'
import { BaseButton } from '../buttons'
import { BaseInput } from '../inputs'
import { Message } from '../../types'
import { ApiUtility, Endpoints, StorageUtility } from '../../utilities'
import { CircularProgress, IconButton, Stack } from '@mui/material'
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded'
import CloseIcon from '@mui/icons-material/Close'
import LinkOffRoundedIcon from '@mui/icons-material/LinkOffRounded'

const POLLING_INTERVAL_MS = 2000
const MAX_FILES = 5
const ALLOWED_TYPES = '.pdf,.jpg,.jpeg,.png,.tiff,.heic,.mp4,.mov'

export default function Chat({ onClose, thread }: { onClose: () => void, thread?: string }) {
    const [messages, setMessages] = useState<Message[] | null>(null)
    const [loading, toggleLoading] = useState<boolean>(false)
    const listRef = useRef<HTMLDivElement>(null)

    const sendMessage = async (body?: string, files?: File[]): Promise<boolean> => {
        try {
            toggleLoading(true)
            if (!!body) {
                await ApiUtility.Post(Endpoints.Messages, { thread, body, type: 'text' })
            }
            if (!!files) {
                body = files.length + ' ' + (files.length === 1 ? 'file' : 'files')

                let response = await ApiUtility.Post(Endpoints.Messages, { thread, body, type: 'files' })
                if (response.success && response.data.uuid) {
                    let newMessage: Message = response.data
                    for (let file of files) {
                        await StorageUtility.UploadObject(file, `messages/${newMessage.uuid}`)
                    }
                }
            }
            toggleLoading(false)
            return true
        } catch (error: any) {
            return false
        }
    }

    const loadMessages = async () => {
        let messagesResponse = await ApiUtility.Get(Endpoints.Messages + '?thread=' + thread)
        if (messagesResponse.success) {
            setMessages(messagesResponse.data as Message[])
        }
    }

    const startPolling = () => {
        return window.setInterval(() => {
            loadMessages()
        }, POLLING_INTERVAL_MS)
    }

    const scrollList = () => {
        if (listRef.current) {
            listRef.current.scroll({ top: listRef.current.scrollHeight, behavior: 'auto' })
        }
    }

    useEffect(() => {
        if (!thread) {
            return
        }

        let interval = startPolling()

        return () => {
            window.clearInterval(interval)
        }
    }, [thread])

    useEffect(() => {
        if (!messages || messages.length < 1) {
            return
        }

        scrollList()
    }, [messages?.length])

    return messages && messages?.length > 0 ? (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', padding: '1em', overflow: 'hidden' }}>
            <div ref={listRef} style={{ overflow: 'scroll', flex: 1 }}>
                {messages && messages.length > 0 && messages.map(el => (
                    <MessageRow key={el.uuid} message={el} onClick={() => { }} />
                ))}
            </div>
            <div>
                <div className='line' style={{ marginBottom: '1em' }} />
                <ChatControls thread={thread} onClose={onClose} onSubmit={sendMessage} loading={loading} />
            </div>
        </div>
    ) : (
        <div className='row flex-center' style={{ height: '100%' }}>
            <CircularProgress style={{ color: 'var(--ui-ma-blue) ' }} />
        </div>
    )
}

const ChatControls = (props: { loading: boolean, onClose: () => void, onSubmit: (body?: string, files?: File[]) => Promise<boolean>; thread?: string }) => {
    const fileInputId = 'chat-file-picker'
    const [message, setMessage] = useState<string>('')
    const [uploads, setUploads] = useState<File[]>()
    const sendEnabled = (message?.trim().length > 0) || (!!uploads && uploads?.length > 0)

    const submit = async () => {
        const success = await props.onSubmit(message.trim(), uploads)
        if (success) {
            setMessage('')
            setUploads([])
        }
    }

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.stopPropagation()
            submit()
        }
    }

    const openFilePicker = (e: any) => {
        e.preventDefault()

        let fileElement = document.getElementById(fileInputId)
        if (!fileElement) {
            return
        }

        fileElement.click()
    }

    const fileSelectionChanged = (e: ChangeEvent) => {
        let input = document.getElementById(fileInputId) as HTMLInputElement
        if (!input || !input.files || input.files.length < 1) {
            return
        }

        if (input.files.length > MAX_FILES || (uploads && uploads.length + input.files.length > MAX_FILES)) {
            alert(`Chat messages are currently limited to ${MAX_FILES} files per message.`)
            return
        }

        let list: File[] = uploads?.slice(0) || []

        for (let i = 0; i <= input.files.length; i++) {
            let file = input.files.item(i)
            if (!!file && list.filter(el => el.name === file?.name).length === 0) {
                list.push(file)
            }
        }

        setUploads(list)
    }

    return (
        <Stack spacing={1}>
            <input type='file' id={fileInputId} accept={ALLOWED_TYPES} multiple style={{ display: 'none' }} onChange={fileSelectionChanged} />
            {!!uploads && uploads.map(el => (
                <div key={el.name} className='noselect file-list-item' style={{ padding: '2px', marginTop: '0px' }}>
                    <p className='file-name' style={{ fontSize: '0.75em' }}>{el.name}</p>
                    <IconButton onClick={() => setUploads(uploads.filter(f => f.name !== el.name))}>
                        <CloseIcon fontSize='small' />
                    </IconButton>
                </div>
            ))}
            <Stack spacing={1} direction='row'>
                <BaseInput placeholder='What do you want to say?' characterLimit={500} value={message} onKeyUp={handleKeyUp} onChange={(e) => setMessage(e.target.value)} fullWidth />
                <IconButton style={{ height: '2em', width: '2em' }} onClick={openFilePicker} disabled={!!uploads && uploads?.length >= MAX_FILES}>
                    {!uploads || uploads.length < MAX_FILES
                        ? <AttachFileRoundedIcon style={{ color: 'var(--ui-dark-blue)' }} fontSize='small' />
                        : <LinkOffRoundedIcon style={{ color: 'var(--ui-dark-blue)', rotate: '90deg' }} fontSize='small' />
                        // : <Tooltip text={`Chat messages are currently limited to ${MAX_FILES} files per message.`} />
                    }
                </IconButton>
            </Stack>
            <Stack spacing={1} direction='row'>
                <BaseButton text='Close' fullWidth disabled={props.loading} leftIcon='arrowLeft' variant='delete' onClick={props.onClose} />
                <BaseButton text='Send' fullWidth disabled={!sendEnabled} rightIcon='send' onClick={submit} loading={props.loading} />
            </Stack>
        </Stack>
    )
}