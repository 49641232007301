import { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './styles/app.css'
import './styles/containers.css'
import './styles/inputs.css'
import './styles/typography.css'
import * as Pages from './pages'
import { urls } from './pages'
import { AuthUtility } from './utilities'

function App() {
  useEffect(() => {
    AuthUtility.Initialize()
  }, [])

  return (
    <Router>
      <div className='shell'>
        <Switch>
          <Route exact path={urls.catalog}>
            <Pages.Catalog />
          </Route>
          <Route exact path={urls.dashboard}>
            <Pages.Dashboard />
          </Route>
          <Route exact path={urls.dealership}>
            <Pages.Dealership />
          </Route>
          <Route exact path={urls.dealerships}>
            <Pages.Dealerships />
          </Route>
          <Route exact path={urls.help}>
            <Pages.Help />
          </Route>
          <Route exact path={urls.invitations}>
            <Pages.Invitations />
          </Route>
          <Route exact path={urls.landing}>
            <Pages.Landing />
          </Route>
          <Route path={urls.messages + ':thread?'}>
            <Pages.Messages />
          </Route>
          <Route exact path={urls.newOrder}>
            <Pages.NewOrder />
          </Route>
          <Route path={urls.orders + ':jobNumber?'}>
            <Pages.Orders />
          </Route>
          <Route exact path={urls.pastOrders}>
            <Pages.Orders />
          </Route>
          <Route exact path={urls.profile}>
            <Pages.Profile />
          </Route>
          <Route exact path={urls.report}>
            <Pages.Report />
          </Route>
          <Route exact path={urls.users}>
            <Pages.Users />
          </Route>
          {/* Catch invalid routes */}
          <Route>
            <Pages.Error />
          </Route>
        </Switch>
      </div>
    </Router>
  )
}

export default App
