import { ChangeEventHandler, FocusEventHandler } from 'react';
import InputMask from 'react-input-mask';
import { FormattingUtility } from '../../utilities';
import BaseInput from './BaseInput';

interface PhoneInputProps {
    defaultValue?: string
    disabled?: boolean
    error?: any
    fullWidth?: boolean
    label: string
    labelClassName?: string
    labelColor?: string
    name?: string
    placeholder?: string
    onBlur?: FocusEventHandler<HTMLInputElement>
    onChange: ChangeEventHandler<HTMLInputElement>
    optional?: boolean
    style?: object
    type?: string
    value?: string
}

export default function PhoneInput(props: PhoneInputProps) {
    return (
        <div style={{ display: 'block', width: props.fullWidth ? '100%' : 150 }}>
            <InputMask
                disabled={props.disabled}
                value={FormattingUtility.unformatPhoneNumber(props.defaultValue || '')}
                mask='+1 (999) 999-9999' //Note: 9 restricts a character to digits. Limited to US phone format
                maskPlaceholder=' '
                name={props.name}
                onBlur={props.onBlur}
                onChange={props.onChange}
            >
                {() => <BaseInput {...props} />}
            </InputMask>
        </div>
    );
}