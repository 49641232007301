import { ReactNode } from 'react'
import { ClickAwayListener } from '@mui/base'
import { Popper } from '@mui/material'

type InterfaceProps = { 
    anchor: Element | null
    children: ReactNode
    onClickAway: () => void
    style?: React.CSSProperties
}

export default function Popover(props: InterfaceProps) {
    return (
        <ClickAwayListener onClickAway={props.onClickAway} mouseEvent='onMouseUp'>
            <Popper 
                anchorEl={props.anchor}
                disablePortal={false}
                modifiers={[
                    {
                        name: 'flip',
                        enabled: false,
                        options: {
                            altBoundary: true,
                            rootBoundary: 'document',
                            padding: 8,
                        },
                    },
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            altAxis: true,
                            altBoundary: true,
                            tether: true,
                            rootBoundary: 'viewport',
                            padding: 8,
                        },
                    },
                    {
                        name: 'arrow',
                        enabled: false,
                    },
                ]}
                open={!!props.anchor}
                onClick={event => event.stopPropagation()}
                style={props.style}
                placement='top'
            >
                {props.children}
            </Popper>
        </ClickAwayListener>
    )
}