import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import * as Store from '../../store/slices'
import { BaseDialog } from '../dialogs'
import { Invitation, User } from '../../types'
import { ApiUtility, Endpoints, FormattingUtility } from '../../utilities'
import { IconButton, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

export default function ManageInvitationMenu({ invitation }: { invitation: Invitation }) {
    const dispatch = useAppDispatch()
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null)

    const resendInvitation = async () => {
        if (!window.confirm('Resend invitation? This will reactivate the invitation for 7 days from today.')) {
            return
        }

        let response = await ApiUtility.Post(Endpoints.InvitedUsers, invitation)
        if (!response.success) {
            alert('There was a problem.')
            return
        }

        alert('Success.')
    }

    const deleteInvitation = async () => {
        if (!window.confirm('Are you sure you would like to revoke this invitation?')) {
            return
        }

        const response = await ApiUtility.Delete(Endpoints.InvitedUsers, { token: invitation.token })

        if (response.success) {
            dispatch(Store.deleteInvitation(invitation))
        } else {
            alert('There was an unexpected error while trying to delete the user invitation. Please try again.')
        }
    }

    return (
        <>
            <IconButton onClick={(e) => setAnchor(e.currentTarget)}>
                <MoreVertIcon />
            </IconButton>
            <BaseDialog
                cancellable={true}
                heading={invitation.firstName + ' ' + invitation.lastName}
                isOpen={!!anchor}
                onClose={() => setAnchor(null)}
            >
                <div style={{ minWidth: '300px' }}>
                    <p>Email: {invitation.email}</p>
                    <p>Role: {FormattingUtility.getUserRole(invitation.role)}</p>
                    <div style={{ height: '1px', width: '100%', margin: '0.5em 0', backgroundColor: 'var(--ui-dark-grey)' }}></div>
                    <p>Actions</p>
                    <MenuItem onClick={resendInvitation}>
                        <p>Resend Invitation</p>
                    </MenuItem>
                    <MenuItem onClick={deleteInvitation}>
                        <p>Delete Invitation</p>
                    </MenuItem>
                </div>
            </BaseDialog>
        </>
    )
}