import { useState } from 'react'
import { BaseButton } from '../buttons'
import { BaseInput, Dropdown, PhoneInput } from '../inputs'
import { Dealership, DropDownItem } from '../../types'
import { UserRoles } from '../../types/enums'
import { ApiUtility, FormattingUtility } from '../../utilities'
import { Formik } from 'formik'
import * as Yup from 'yup'

type CreateUserFormProps = {
    dealership?: Dealership
    onSubmit: () => void
    roles: UserRoles[]
}

const FormSchema = Yup.object().shape({
    email: Yup.string().email('Invalid').required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    phoneNumber: Yup.string().matches(FormattingUtility.PhoneNumberRegex, 'Invalid').required('Required'),
    role: Yup.number().min(1, 'Invalid User Type').required('Required'),
    idLocations: Yup.number().required('Required'),
})

export default function Form(props: CreateUserFormProps) {
    const [roles, setRoles] = useState<DropDownItem[]>(props.roles.map(el => ({ key: el, label: FormattingUtility.getUserRole(el) })))
    const [locations, setLocations] = useState<DropDownItem[] | undefined>(props.dealership?.locations.map(el => ({ key: el.idLocations, label: el.name })))

    const submit = async (values: any) => {
        // Ensure role is selected
        if (!values.role) {
            alert('Invalid User Type.')
            return
        }
        // Validate dealership/location
        if (!!props.dealership) {
            if (values.role !== UserRoles.Dealer && !values.idLocations) {
                alert('Please assign this User to a Location.')
                return
            }
        }

        const invite = await ApiUtility.InviteUser({
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: FormattingUtility.unformatPhoneNumber(values.phoneNumber),
            role: values.role,
            idDealerships: props.dealership?.idDealerships,
            idLocations: props.dealership ? values.idLocations : null
        })

        if (!invite.success) {
            alert(invite.message)
            return
        }

        props.onSubmit()
    }

    return (
        <Formik
            initialValues={{
                email: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                role: 0,
                idLocations: 0,
            }}
            validateOnMount={true}
            validationSchema={FormSchema}
            onSubmit={submit}
        >
            {({ values, errors, touched, isValid, handleBlur, handleChange, handleSubmit, isSubmitting, setValues }) => (
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <Dropdown
                        label='User Type'
                        items={roles}
                        fullWidth
                        error={touched.role && errors.role || ''}
                        onSelect={(selected, item) => setValues({ ...values, role: item.key as number })}
                    />
                    {FormattingUtility.isLocationRequiredForUser(values.role) && locations && (
                        <Dropdown
                            label='Location'
                            items={locations}
                            fullWidth
                            error={touched.idLocations && errors.idLocations || ''}
                            onSelect={(selected, item) => setValues({ ...values, idLocations: item.key as number })}
                        />
                    )}
                    <div style={{ width: '100%', height: '2px', backgroundColor: 'var(--ui-dark-grey)', margin: '2em 0em' }} />
                    <BaseInput name='firstName' label='First Name' defaultValue={values.firstName} error={touched.firstName && errors.firstName ? errors.firstName : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                    <BaseInput name='lastName' label='Last Name' defaultValue={values.lastName} error={touched.lastName && errors.lastName ? errors.lastName : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                    <BaseInput name='email' label='Email' defaultValue={values.email} error={touched.email && errors.email ? errors.email : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                    <PhoneInput name='phoneNumber' label='Phone Number' defaultValue={values.phoneNumber} error={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                    {/* TODO: Show dealership dropdown if selected role is Designer */}
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <BaseButton text='Create' disabled={!isValid} onClick={handleSubmit} type='submit' rightIcon='arrowRight' loading={isSubmitting} />
                    </div>
                </form>
            )}
        </Formik>
    )
}