import { BaseButton } from '../buttons'
import { BaseDialog } from './'
import { PasswordInput } from '../inputs'
import { AuthUtility } from '../../utilities'
import { Formik } from 'formik'
import * as Yup from 'yup'

interface ChangePasswordDialogProps {
    onCancel: () => void
    onConfirm: () => void
    open: boolean
}

function ChangePasswordDialog(props: ChangePasswordDialogProps) {
    const FormSchema = Yup.object().shape({
        confirmNewPassword: Yup.string().required('Required').when('newPassword', {
            is: (val: string) => val && val.length > 0,
            then: Yup.string().oneOf(
                [Yup.ref('newPassword')],
                'Must match New Password field.'
            )
        }),
        currentPassword: Yup.string().min(8, 'Must be 8 characters').required('Required'),
        newPassword: Yup.string().min(8, 'Must be 8 characters').required('Required')
    })

    return (
        <BaseDialog
            cancellable={true}
            onClose={props.onCancel}
            isOpen={props.open}
            heading='Change Password'
        >
            <p className='subtle' style={{ marginBottom: '1em' }}>Your new password is required to be at least 8 characters in length.</p>
            <Formik
                initialValues={{ confirmNewPassword: '', currentPassword: '', newPassword: '' }}
                validationSchema={FormSchema}
                onSubmit={async (values) => {
                    let result = await AuthUtility.ChangePassword(values.currentPassword, values.confirmNewPassword)

                    if (!result.success) {
                        alert(result.errorCode === 'NotAuthorizedException' ? 'The current password you provided was incorrect.' : 'Something went wrong while trying to update your password.')
                        return
                    }

                    props.onConfirm()
                }}
            >
                {({ values, errors, touched, dirty, isValid, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <PasswordInput name='currentPassword' error={touched.currentPassword && errors.currentPassword ? errors.currentPassword : ''} label='Current Password' fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                        <PasswordInput name='newPassword' error={touched.newPassword && errors.newPassword ? errors.newPassword : ''} label='New Password' fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                        <PasswordInput name='confirmNewPassword' error={touched.confirmNewPassword && errors.confirmNewPassword ? errors.confirmNewPassword : ''} label='Confirm New Password' fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <BaseButton text='Update' disabled={!dirty || !isValid} rightIcon='arrowRight' type='submit' loading={isSubmitting} />
                        </div>
                    </form>
                )}
            </Formik>
        </BaseDialog>
    )
}

export default ChangePasswordDialog