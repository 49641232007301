import { ReactNode, useEffect } from 'react'
import { useHistory } from 'react-router'
import { SideMenu, UserWidget } from '.'
import { urls } from '../../pages'
import { AuthUtility } from '../../utilities'

interface BasePageProps {
    children: ReactNode
    floatingAction?: ReactNode
    hideTitleRow?: boolean
    requireAuth: boolean
    navFunction?: (url: string) => void
    title: string
}

export default function Page(props: BasePageProps) {
    const history = useHistory()

    const checkAuth = async () => {
        if (!props.requireAuth) {
            return
        }

        let signedIn = await AuthUtility.IsSignedIn()

        if (!signedIn) {
            history.push(urls.error)
        }
    }

    useEffect(() => {
        checkAuth()
    }, [history.location.pathname])

    useEffect(() => {
        document.title = props.title + ' | Modern Amish'
    }, [props.title])

    return (
        <div className='page'>
            <SideMenu navFunction={props.navFunction} />
            <div className='page-content'>
                {props.hideTitleRow && (
                    <div style={{ height: '1em' }}></div>
                )}
                {!props.hideTitleRow && (
                    <div className='ui-row' style={{ justifyContent: 'space-between', marginTop: '1em' }}>
                        <h3>{props.title}</h3>
                        <UserWidget />
                    </div>
                )}
                {props.children}
            </div>
            {!!props.floatingAction && (
                <div style={{ position: 'absolute', right: 20, bottom: 20 }}>
                    {props.floatingAction}
                </div>
            )}
        </div>
    );
}