import React from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CloseIcon from '@mui/icons-material/Close';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';

interface IconButtonProps {
  icon: 'add' | 'close' | 'remove'
  onClick(): void
  size?: 'small' | 'large'
  style?: React.CSSProperties
}

const IconButton: React.FC<IconButtonProps> = (props) => {
  const getIcon = () => {
    switch (props.icon) {
      case 'add':
        return <AddRoundedIcon {...props.size ? { fontSize: props.size } : {}} htmlColor='var(--app-primary)' />
      case 'close':
        return <CloseIcon {...props.size ? { fontSize: props.size } : {}} htmlColor='var(--app-primary)' />
      case 'remove':
        return <RemoveRoundedIcon {...props.size ? { fontSize: props.size } : {}} htmlColor='var(--app-primary)' />
    }
  }

  return (
    <button
      {...props}
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '2em', 
        width: '2em',
        margin: 0,
        outline: 'none',
        border: '2px solid var(--app-primary)',
        backgroundColor: 'transparent',
        borderRadius: 5,
        ...props.style,
      }}
      onClick={props.onClick}
    >
      {getIcon()}
    </button>
  );
};

export default IconButton;