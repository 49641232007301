import { User } from './../types/index';
import { AuthUtility, FormattingUtility } from './';

type RequestOptions = {
    body?: string | null
    cache: 'no-cache'
    headers: {
        [key: string]: string
    }
    method: 'GET' | 'PUT' | 'POST' | 'DELETE'
    mode: 'cors',
    redirect: 'error'
}

const getRequestOptions = async (method: RequestOptions['method'], body: any, useAuth: boolean): Promise<RequestOptions> => {
    let options: RequestOptions = {
        cache: 'no-cache',
        method,
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        redirect: 'error',
    };

    options.body = body ? JSON.stringify(body) : null

    if (useAuth) {
        const token = await AuthUtility.GetAuthToken();
        options.headers['Authorization'] = 'Bearer ' + token;
    }

    return options;
}

const formatResponse = async (response: Response) => {
    let json = await response.json();
    if (json.error) {
        // console.error('[API Utility] Error: ' + json.error, json);
    }
    return json;
}

const ApiUtility = {
    Delay: async (ms: number): Promise<{ success: boolean }> => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({ success: true })
            }, ms)
        })
    },
    //REST METHODS
    Get: async (endpoint: string, useAuth: boolean = true) => {
        try {
            let options = await getRequestOptions('GET', null, useAuth);
            let response = await fetch(endpoint, options);

            return await formatResponse(response);
        } catch (error) {
            return { error: true };
        }
    },
    Put: async (endpoint: string, body: any, useAuth: boolean = true) => {
        try {
            let options = await getRequestOptions('PUT', body, useAuth);
            let response = await fetch(endpoint, options);
            return await formatResponse(response);
        } catch (error) {
            return { error: true };
        }
    },
    Post: async (endpoint: string, body: any, useAuth: boolean = true) => {
        try {
            let options = await getRequestOptions('POST', body, useAuth);
            let response = await fetch(endpoint, options);
            return await formatResponse(response);
        } catch (error) {
            return { error: true };
        }
    },
    Delete: async (endpoint: string, body: any, useAuth: boolean = true) => {
        try {
            let options = await getRequestOptions('DELETE', body, useAuth);
            let response = await fetch(endpoint, options);
            return await formatResponse(response);
        } catch (error) {
            return { error: true };
        }
    },
    //HELPERS
    InviteUser: async (values: any): Promise<{ success: boolean, message: string }> => {
        try {
            const user = {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phoneNumber: FormattingUtility.unformatPhoneNumber(values.phoneNumber),
                role: values.role,
                idDealerships: values.idDealerships,
                idLocations: values.idLocations,
            } as unknown as User

            let response = await ApiUtility.Put(Endpoints.Users, user)

            if (response.success) {
                return { success: true, message: `An invitation has been sent to ${values.email}. The invitation will expire in 7 days.` }
            }

            let message = ''
            switch (response.statusCode) {
                case 401:
                    message = 'You will need to sign in again before retrying your request.'
                    break
                case 403:
                    message = 'You do not have sufficient permission to invite this user. If you believe this was an error, please report this issue via the Help Center.'
                    break
                case 409:
                    message = 'The email address you provided is already in use.'
                    break
                case 400:
                case 404:
                default:
                    message = 'Something went wrong while trying to invite this user. Please try again.'
                    break
            }

            return { success: false, message }
        } catch (error) {
            return { success: false, message: 'Something went wrong while trying to invite this user. Please try again.' }
        }
    },
}

const ApiBaseUrl = process.env.REACT_APP_API_HOST

const Endpoints = {
    AccountRecovery: ApiBaseUrl + '/users/recovery',
    Dealerships: ApiBaseUrl + '/dealerships',
    Invitation: ApiBaseUrl + '/invitation',
    InvitedUsers: ApiBaseUrl + '/users/invited',
    Locations: ApiBaseUrl + '/dealerships/locations',
    Messages: ApiBaseUrl + '/messages',
    MessageThreads: ApiBaseUrl + '/messages/threads',
    MessageUploads: ApiBaseUrl + '/messages/uploads',
    Notifications: ApiBaseUrl + '/notifications',
    Orders: ApiBaseUrl + '/orders',
    OrderUploads: ApiBaseUrl + '/orders/uploads',
    Products: ApiBaseUrl + '/products',
    Profile: ApiBaseUrl + '/users/profile',
    ProfilePhone: ApiBaseUrl + '/users/profile/phone',
    ProfileEmail: ApiBaseUrl + '/users/profile/email',
    Storage: ApiBaseUrl + '/storage',
    Storage_OrderDesigns: ApiBaseUrl + '/storage/orders/designs',
    Storage_OrderInvoice: ApiBaseUrl + '/storage/orders/invoice',
    Subscriptions: ApiBaseUrl + '/subscriptions',
    Users: ApiBaseUrl + '/users',
    UserStatus: ApiBaseUrl + '/users/status',
}

export { ApiUtility, Endpoints };