import { Room } from '../../types'

const RoomsReview = ({ rooms }: { rooms: Room[] }) => {
    return (
        !!rooms && rooms.length > 0
            ? (
                <div style={{ overflow: 'scroll', maxHeight: '100%' }}>
                    {rooms.map((room, roomIndex) => (
                        <div key={room.key} style={{ borderBottomColor: 'lightgrey', borderBottomStyle: 'solid', borderBottomWidth: (roomIndex < rooms.length - 1) ? '1px' : 0, paddingBottom: '8px', marginBottom: '4px' }}>
                            <h4 style={{ fontSize: '1em', color: 'var(--app-primary)' }}>{room.name}</h4>
                            <div>
                                {room.lineItems && room.lineItems.map((item, itemIndex) => (
                                    <div key={item.key}>
                                        <p style={{ fontFamily: 'monospace', fontStyle: 'italic' }}>{item.itemNumber} - {item.sku || '[NO SKU]'}: {item.description}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            )
            : (
                <div><i>There is no room or cabinet information available for this order.</i></div>
            )
    )
}

export default RoomsReview