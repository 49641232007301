import { DataGrid, GridColDef, GridEvents, GridEventListener } from '@mui/x-data-grid'

type BaseGridProps = {
    columns: GridColDef[]
    data: any[]
    hideFooter?: boolean
    idKey: string
    maxHeight?: string
    onRowClick?: GridEventListener<GridEvents.rowClick>
}

export default function BaseGrid(props: BaseGridProps) {
    return (
        <div className='noselect' style={ props.maxHeight ? { maxHeight: props.maxHeight, overflowY: 'scroll' } : {}}>
            <DataGrid
                autoHeight={true}
                columns={props.columns}
                disableColumnFilter={true}
                disableColumnMenu={true}
                disableColumnSelector={true}
                hideFooter={!!props.hideFooter}
                disableSelectionOnClick={true}
                getRowId={(el) => el[props.idKey]}
                rows={props.data}
                onRowClick={props.onRowClick}
                style={{ cursor: 'pointer' }}
            />
        </div>
    )
}