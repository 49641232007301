import { Drawer, IconButton } from '@mui/material'
import Chat from './Chat'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import * as Store from '../../store/slices'
import { useEffect, useMemo } from 'react'
import { Thread } from '../../types'
import { MoreVert } from '@mui/icons-material'

type MessageDrawerProps = {
    isOpen: boolean
    onClose: () => void
    thread: string | undefined
}

const MessageDrawer = ({ isOpen, onClose, thread }: MessageDrawerProps) => {
    const dispatch = useAppDispatch()
    const threads = useAppSelector(Store.getThreads)
    const currentThread = useMemo<Thread>(() => {
        return threads.filter(el => el.uuid === thread)[0]
    }, [threads, thread])

    const chatCanceled = () => {
        dispatch(Store.setCurrentThread(null))
        onClose()
    }

    useEffect(() => {
        if (isOpen && !!thread) {
            dispatch(Store.setCurrentThread(thread))
        }
    }, [isOpen, thread])

    return (
        <Drawer
            anchor='right'
            onClose={chatCanceled}
            open={isOpen}
            PaperProps={{
                style: {
                    width: '40vw',
                    minWidth: '500px',
                }
            }}
        >
            <div className='ui-row' style={{ margin: '1em 0px', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className='column' style={{ maxWidth: '80%' }}>
                    <h5 style={{ fontSize: '1.2em', fontWeight: 600, color: 'var(--app-primary)' }}>{!!currentThread ? currentThread.name : 'Loading'}</h5>
                </div>
                {/* {!isOrderThread && (
                    <IconButton size='small' onClick={() => alert('Confirm, then delete')}>
                        <MoreVert fontSize='small' />
                    </IconButton>
                )} */}
            </div>
            <div style={{ padding: '0px 1em' }}>
                <div className='line' style={{}} />
            </div>
            <Chat thread={thread} onClose={chatCanceled} />
        </Drawer>
    )
}

export default MessageDrawer