import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'

export interface AppState {
    collapsed: boolean
}

const initialState: AppState = {
    collapsed: false
}

//Create slice in App State
export const slice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        resetAppSlice: () => initialState,
        setAppBarState: (state, action: PayloadAction<boolean>) => {
            state.collapsed = action.payload
        },
    },
})

//Actions
export const { setAppBarState, resetAppSlice } = slice.actions

//Selectors
export const getAppBarState = (state: RootState) => state.app.collapsed

//Export Reducer
export default slice.reducer