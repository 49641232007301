import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Thread } from '../../types'
import { RootState } from '..'

export interface MessagesState {
    currentThread: string | null
    threads: Thread[]
}

const initialState: MessagesState = {
    currentThread: null,
    threads: []
}

//Create slice in App State
export const messageSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        resetMessagesSlice: () => initialState,
        setCurrentThread: (state, action: PayloadAction<string | null>) => {
            state.currentThread = action.payload
        },
        setThreads: (state, action: PayloadAction<Thread[]>) => {
            state.threads = action.payload
        },
    },
})

//Actions
export const { resetMessagesSlice, setCurrentThread, setThreads } = messageSlice.actions

//Selectors
export const getCurrentThread = (state: RootState) => state.messages.currentThread
export const getThreads = (state: RootState) => state.messages.threads

//Export Reducer
export default messageSlice.reducer