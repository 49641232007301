import { AddRounded, RemoveRounded } from '@mui/icons-material'
import { Collapse, IconButton } from '@mui/material'
import { useState } from 'react'
import { BasePage, Card } from '../components/layout'

const faqs = [
    { question: 'What am I looking at on my dashboard?', answer: 'When logging in for the first time you will always load into your specific dashboard. This page will show you an overview of everything in the system relating to you and your orders. The notifications center will change automatically with updates in real time. Upcoming deliveries show any deliveries scheduled. Recent orders is a compiled list of your most recent orders, and Message Center will show quick links to conversations organized by job number.' },
    // { question: 'I purchased a new computer and need to reinstall CabEntry, how can I do that?', answer: 'On the left side of the Dashboard at the bottom of the navigation window you will see quick links. These links let you report a problem (bug) with the dashboard application, allow you to quickly visit Modern Amish Website, as well as download a fresh version of CabEntry. CabEntry in this location will always be the most up to date build.' },
    { question: 'Where does the navigation take me?', answer: 'Dashboard will always take you to your dashboard, or home screen. Dealership shows information about your specific dealership, you can edit this information or invite users if you are a dealer. View order shows a detailed list of each order you currently have at Modern Amish. Past Orders are an archive of delivered jobs, once a current order delivers, it moves to Past Orders. Message Center shows a list of all current conversations you have about orders. Help center is where you are currently. We have additional FAQs and video tutorials coming soon.' },
    { question: 'How do I invite a new user to my dealership?', answer: 'Clicking the Dealership button on the left navigation will open your dealership information. In the users section click the blue button that says invite user. Select the user type from the drop down menu, then your location (if you have multiple locations). Then you must enter the name, email, and phone number for the user you want to invite and click create. This will send an email with instructions to the user you are inviting.' },
    { question: 'How do I remove a user from my dealership?', answer: 'This feature is coming soon.' },
    { question: 'How can I get information about an order?', answer: 'Each job has its own message center built in. This allows for easy tracking of data for specific jobs. To access this feature simply click an order, either from the View Orders page, or from your Dashboard. When the order loads, you will see a chat icon in the bottom right corner of your screen. Clicking on that button will open the message center for the specific job. A Modern Amish representative will answer your question as quickly as possible. You will get a notification on the dashboard when a message has been sent.' },
    { question: 'How do I unsubscribe from notifications about orders?', answer: 'This feature is coming soon.' },
    { question: 'When will my order be delivered?', answer: 'You will see notifications about each order on your dashboard when logging in. You can also click View Orders in the left navigation and find out information about your order. If the order has an available delivery date you will see the date in the delivery date column. Delivery date can take up to 72 hours to populate in the system. You can also contact support@maorders.com or as a question using the message center for the job you have questions about.' },
]

/* ----- Commenting the code as Video Tutorials section on the page is currently hidden ----- */
/*
const tutorialVideos = [
    { title: 'Inviting a Designer user', url: 'https://www.google.com/search?q=modern+amish+cabinetry', description: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.' },
    { title: 'Revoking an invitation to the system', url: 'https://www.google.com/search?q=modern+amish+cabinetry', description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.' },
    { title: 'Change my account/login email', url: 'https://www.google.com/search?q=modern+amish+cabinetry', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.' },
    { title: 'Change my MFA phone', url: 'https://www.google.com/search?q=modern+amish+cabinetry', description: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.' },
    { title: 'Update a profile picture', url: 'https://www.google.com/search?q=modern+amish+cabinetry', description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. ' },
    { title: 'Reset my password', url: 'https://www.google.com/search?q=modern+amish+cabinetry', description: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.' },
    { title: 'Cancel an order', url: 'https://www.google.com/search?q=modern+amish+cabinetry', description: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.' },
]
*/

function Section({ question, answer }: { question: string, answer: string }) {
    const [expanded, toggleExpanded] = useState<boolean>(false)

    return (
        <div className='row' style={{ alignItems: expanded ? 'flex-start' : 'center', borderBottom: '1px solid var(--ui-lightgrey)', padding: '0.5em 0px', height: '100%' }}>
            <div className='column' style={{ alignContent: 'flex-start', alignItems: 'flex-start', height: '100%' }}>
                <IconButton onClick={() => toggleExpanded(!expanded)}>
                    {expanded
                        ? <RemoveRounded fontSize='small' />
                        : <AddRounded fontSize='small' />
                    }
                </IconButton>
            </div>
            <div className='column' style={{ flex: 1, height: '100%' }}>
                <p style={{ fontWeight: '500' }}>
                    {question}</p>
                <Collapse in={expanded} timeout='auto' unmountOnExit>
                    <p>{answer}</p>
                </Collapse>
            </div>
        </div>
    )
}

export default function Page() {
    return (
        <BasePage title='Help Center' requireAuth={true}>
            <div className='ui-row'>
                <p className='wizard-instructions'>Here you will find answers to common questions and short instructional videos on certain features.</p>
            </div>
            <div className='ui-row'>
                <Card title='Frequently Asked Questions' fullWidth>
                    {faqs.map(el => (
                        <Section key={el.question} {...el} />
                    ))}
                </Card>
            </div>
            {/*  
            <div className='ui-row' style={{ marginTop: '2em' }}>
                <h3>Video Tutorials - coming soon</h3>
            </div>
            <div className='ui-row grid-container'>
                <div className='grid'>
                    {tutorialVideos.map(el => (
                        <Card key={el.title} title={el.title}>
                            <div className='row'>
                                <div className='column'>
                                    <div style={{ border: '1px solid var(--ui-sky-blue)', height: '6em', width: '6em', padding: '0.5em' }}>
                                        <img src='/images/logos/ma-black.svg' alt={`thumbnail for ${el.title}`} style={{ height: '100%', width: '100%' }} />
                                    </div>
                                </div>
                                <div className='column' style={{ flex: 1, marginLeft: '1em' }}>
                                    <p>{el.description}</p>
                                </div>
                            </div>
                        </Card>
                    ))}
                </div>
            </div>
            */}
        </BasePage>
    );
}