import { urls } from './'
import { BaseButton, RoomChip } from '../components/buttons'
import { RoomForm, RoomsReview } from '../components/forms'
import { BaseInput, Checkbox, Dropdown, FileDrop, PhoneInput } from '../components/inputs'
import { BasePage, Card, Row, Table } from '../components/layout'
import { ProgressIndicator } from '../components/ui'
import { Formik } from 'formik'
import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Stack } from '@mui/material'
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded'
import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import * as Store from '../store/slices'
import { Dealership, Order, Room } from '../types'
import { OrderStatus, UserRoles } from '../types/enums'
import { ApiUtility, Endpoints, FormattingUtility, StorageUtility } from '../utilities'
import { v4 } from 'uuid'
import * as Yup from 'yup'

export default function Page() {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const currentUser = useAppSelector(Store.getCurrentUser)
    const dealerships = useAppSelector<Dealership[]>(Store.getDealerships)
    const orders = useAppSelector<Order[]>(Store.getOrders)
    const draftOrder = useAppSelector(Store.getDraftOrder)
    const [step, setStep] = useState<number>(1)
    const [currentRoom, setCurrentRoom] = useState<string>(draftOrder?.rooms ? draftOrder.rooms[0].key : '')
    const selectedDealership = useMemo(() => {
        return dealerships.find(dealer => dealer.idDealerships === draftOrder?.idDealerships)
    }, [draftOrder?.idDealerships])
    const selectedLocation = useMemo(() => {
        return selectedDealership?.locations.find(location => location.idLocations === draftOrder?.idLocations)
    }, [draftOrder?.idLocations])
    const restrictedOrderFlow = useMemo(() => {
        return !!currentUser && [UserRoles.Dealer, UserRoles.Designer, UserRoles.LocationBillingContact, UserRoles.LocationManager].includes(currentUser.role)
    }, [currentUser?.role])
    const [sitePlanFiles, setSitePlanFiles] = useState<File[] | null>(null)
    const [cabEntryFiles, setCabEntryFiles] = useState<File[] | null>(null)
    const [createEstimate, setCreateEstimate] = useState<boolean>(true)

    const getTitle = (): string => {
        switch (step) {
            case 1:
                return 'Dealership Information'
            case 2:
                return 'Job Information'
            case 3:
                return 'Delivery Address'
            case 4:
                return 'Room and Cabinet Details'
            case 5:
                return 'Upload Files'
            case 6:
                return 'Review'
            case 7:
                return 'Success!'
            default:
                return 'Error'
        }
    }

    const handleRoomSubmit = (room: Room) => {
        if (!draftOrder) {
            return
        }
        let rooms = FormattingUtility.updateRoomWithinOrderRooms(draftOrder, room)
        dispatch(Store.updateDraftOrder({ rooms }))
    }

    const submit = async () => {
        // 0. Confirm
        if (!window.confirm('Are you sure that you want to submit this order?')) {
            return
        }

        // 0. Create Order
        let order = await submitOrder()
        if (!order) {
            return
        }

        // 0. Upload Site Plan files
        let uploadedSitePlans = await uploadSitePlans(order.uuid)
        if (!uploadedSitePlans) {
            alert('Issue: Site plans failed to upload')
            return
        }

        // 0. Upload CabEntry files
        let uploadedCabEntryFile = await uploadCabEntryFile(order.uuid)
        if (!uploadedCabEntryFile) {
            alert('Issue: Cab entry file failed to upload')
            return
        }

        dispatch(Store.setOrders([...orders, order]))
        setStep(7)
    }

    const submitOrder = async (): Promise<Order | null> => {
        // 0. Validate
        if (!draftOrder || !draftOrder.idDealerships || !draftOrder.idLocations) {
            return null
        }

        const body = {
            ...draftOrder,
            deliveryPhone: FormattingUtility.unformatPhoneNumber(draftOrder.deliveryPhone),
            createEstimate,
        }

        // 0. Create Order
        let response = await ApiUtility.Put(Endpoints.Orders, body)

        if (!response.success) {
            alert('There was a problem submitting the order.')
            return null
        }

        return response.data
    }

    const uploadSitePlans = async (uuid: string): Promise<boolean> => {
        if (!sitePlanFiles || sitePlanFiles.length < 1) {
            return true
        }

        for (const file of sitePlanFiles) {
            await StorageUtility.UploadObject(file, `orders/${uuid}/plans`)
        }

        return true
    }

    const uploadCabEntryFile = async (uuid: string): Promise<boolean> => {
        if (!cabEntryFiles) {
            return true
        }

        for (const file of cabEntryFiles) {
            await StorageUtility.UploadObject(file, `orders/${uuid}/cabentry`)
        }

        return true
    }

    const addSitePlanFiles = (files: File[]): void => {
        let list: File[] = []

        for (let newFile of files) {
            let match = sitePlanFiles?.find(el => el.name === newFile.name && el.lastModified === newFile.lastModified)

            if (match) {
                continue
            }

            list.push(newFile)
        }

        setSitePlanFiles(prev => prev ? prev.concat(list) : list)
    }

    const addCabentryFiles = (files: File[]): void => {
        let list: File[] = []

        for (let newFile of files) {
            let match = cabEntryFiles?.find(el => el.name === newFile.name && el.lastModified === newFile.lastModified)

            if (match) {
                continue
            }

            list.push(newFile)
        }

        setCabEntryFiles(prev => prev ? prev.concat(list) : list)
    }

    const onCancel = () => {
        if (!window.confirm('Are you sure that you would like to cancel this Draft?')) {
            return
        }

        resetWizard()
    }

    const WizardNav = (props: { enableNext?: boolean, onNext: () => void, showSubmit?: boolean }) => {
        const [loading, toggleLoading] = useState<boolean>(false)
        const _submit = async () => {
            toggleLoading(true)
            await submit()
            toggleLoading(false)
        }
        return (
            <span>
                <BaseButton
                    disabled={loading}
                    leftIcon='x'
                    onClick={onCancel}
                    style={{ marginRight: '1em' }}
                    text='Cancel'
                    variant='delete'
                />
                <BaseButton
                    text='Previous'
                    tabIndex={0}
                    disabled={step === 1 || loading}
                    leftIcon='arrowLeft'
                    onClick={() => setStep(step - 1)}
                    style={{ marginRight: '1em' }}
                    type='submit'
                    variant='tertiary'
                />
                {!props.showSubmit && (
                    <BaseButton
                        text='Proceed'
                        tabIndex={0}
                        disabled={!props.enableNext}
                        rightIcon='arrowRight'
                        onClick={() => { props.onNext(); setStep(step + 1) }}
                        variant='tertiary'
                    />
                )}
                {props.showSubmit && (
                    <BaseButton
                        text='Submit'
                        tabIndex={0}
                        rightIcon='arrowRight'
                        loading={loading}
                        onClick={_submit}
                        variant='tertiary'
                    />
                )}
            </span>
        )
    }

    const resetWizard = () => {
        const roomKey = v4()
        const draft = {
            status: OrderStatus.Submitted,
            rooms: [{ key: roomKey } as Room],
            idDealerships: currentUser?.dealership?.idDealerships
        } as Partial<Order>
        dispatch(Store.resetDraftOrder(draft))
        setCurrentRoom(roomKey)
        setStep(1)
    }

    const addNewRoomToDraftOrder = () => {
        const newRoom = FormattingUtility.getEmptyRoom()
        let list: Room[] = JSON.parse(JSON.stringify(draftOrder!.rooms)) || []
        list.push(newRoom)
        dispatch(Store.updateDraftOrder({ rooms: list }))
        setCurrentRoom(newRoom.key)
    }

    useEffect(() => {
        if (!currentUser) {
            return
        }

        resetWizard()
    }, [])

    return (
        <BasePage
            title={getTitle()}
            requireAuth={true}
            navFunction={(url) => {
                if (!FormattingUtility.isOrderEmpty(draftOrder) && !window.confirm('There is an unsaved order. Are you sure you\'d like to lose the current progress?')) {
                    return
                }
                resetWizard()
                history.push(url)
            }}>
            {/* Dealership Info */}
            {step === 1 && (
                <>
                    <div className='ui-row' style={{ justifyContent: 'space-between' }}>
                        <p className='wizard-instructions'>Select the Dealership to which this Order belongs.</p>
                        <WizardNav enableNext={!!selectedLocation} onNext={() => { }} />
                    </div>
                    <div className='ui-row' style={{ width: '600px' }}>
                        <div className='dashboard-card' style={{ width: '100%' }}>
                            <Dropdown
                                label='Dealership'
                                defaultValue={selectedDealership?.name || ''}
                                items={dealerships.map(el => ({ key: el.idDealerships, label: el.name }))}
                                onSelect={(selected) => {
                                    const d = dealerships.find(el => el.name === selected)!
                                    dispatch(Store.updateDraftOrder({ idDealerships: d.idDealerships, idLocations: d.locations.length === 1 ? d.locations[0].idLocations : 0 }))
                                }}
                                disabled={restrictedOrderFlow}
                                fullWidth
                            />
                            {!!selectedDealership &&
                                <Dropdown
                                    label='Location'
                                    defaultValue={selectedLocation?.name || ''}
                                    items={selectedDealership.locations.map(el => ({ key: el.idLocations, label: el.name }))}
                                    onSelect={(selected) => {
                                        const l = selectedDealership.locations.find(el => el.name === selected)
                                        dispatch(Store.updateDraftOrder({ idLocations: l?.idLocations }))
                                    }}
                                    fullWidth
                                />
                            }
                        </div>
                    </div>
                </>
            )}
            {/* Job Info */}
            {step === 2 && draftOrder && (
                <Formik
                    initialValues={draftOrder}
                    validateOnMount={true}
                    validationSchema={Yup.object().shape({
                        jobName: Yup.string().required('Required'),
                        listPrice: Yup.string(),
                        purchaseOrder: Yup.string(),
                        shippingCost: Yup.string(),
                    })}
                    onSubmit={(values) => { dispatch(Store.updateDraftOrder(values)) }}
                >
                    {({ values, touched, errors, isValid, handleSubmit, handleBlur, handleChange, setValues }) => (
                        <>
                            <div className='ui-row' style={{ justifyContent: 'space-between' }}>
                                <p className='wizard-instructions'>Enter the name for this Order, and customer's PO Number if available.</p>
                                <WizardNav enableNext={isValid} onNext={handleSubmit} />
                            </div>
                            <div className='ui-row' style={{ width: '600px' }}>
                                <div className='dashboard-card' style={{ width: '100%' }}>
                                    <BaseInput name='jobName' label='Job Name' value={values.jobName} error={touched.jobName && errors.jobName ? errors.jobName : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                    <BaseInput name='purchaseOrder' label='PO Number' optional value={values.purchaseOrder} error={touched.purchaseOrder && errors.purchaseOrder ? errors.purchaseOrder : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                    {!restrictedOrderFlow && (
                                        <Dropdown
                                            defaultValue={FormattingUtility.getOrderStatus(values.status!)}
                                            label='Status'
                                            items={FormattingUtility.OrderStatuses_DropdownList}
                                            fullWidth
                                            onSelect={(selected, item) => setValues({ ...values, status: item.key as OrderStatus })}
                                        />
                                    )}
                                    {!restrictedOrderFlow && (
                                        <BaseInput name='listPrice' label='List Price' optional value={values.listPrice} error={touched.listPrice && errors.listPrice ? errors.listPrice : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                    )}
                                    {!restrictedOrderFlow && (
                                        <BaseInput name='shippingCost' label='Shipping Cost' optional value={values.shippingCost} error={touched.shippingCost && errors.shippingCost ? errors.shippingCost : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </Formik>
            )}
            {/* Delivery Info */}
            {step === 3 && draftOrder && (
                <Formik
                    initialValues={draftOrder}
                    validateOnMount={true}
                    validationSchema={Yup.object().shape({
                        deliveryAddress1: Yup.string().required('Required'),
                        deliveryAddress2: Yup.string(),
                        deliveryCity: Yup.string().required('Required'),
                        deliveryContact: Yup.string().required('Required'),
                        deliveryEmail: Yup.string().email('Invalid').required('Required'),
                        deliveryPhone: Yup.string().matches(FormattingUtility.PhoneNumberRegex, 'Invalid').required('Required'),
                        deliveryState: Yup.string().length(2, '2 Letter State Required').required('Required'),
                        deliveryZip: Yup.string().matches(FormattingUtility.ZipCodeRegex, 'Invalid').max(10, 'Invalid').required('Required'),
                    })}
                    onSubmit={(values) => { dispatch(Store.updateDraftOrder(values)) }}
                >
                    {({ values, touched, errors, isValid, handleSubmit, handleBlur, handleChange }) => (
                        <>
                            <div className='ui-row' style={{ justifyContent: 'space-between' }}>
                                <p className='wizard-instructions'>Enter the delivery information of the customer or location who will receive this Order.</p>
                                <WizardNav enableNext={isValid} onNext={handleSubmit} />
                            </div>
                            <div className='ui-row' style={{ width: '600px' }}>
                                <div className='dashboard-card' style={{ width: '100%' }}>
                                    <BaseInput name='deliveryContact' label='Contact' defaultValue={values.deliveryContact} error={touched.deliveryContact && errors.deliveryContact ? errors.deliveryContact : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                    <PhoneInput name='deliveryPhone' label='Phone Number' defaultValue={values.deliveryPhone} error={touched.deliveryPhone && errors.deliveryPhone ? errors.deliveryPhone : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                    <BaseInput name='deliveryEmail' label='Email' defaultValue={values.deliveryEmail} error={touched.deliveryEmail && errors.deliveryEmail ? errors.deliveryEmail : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                    <BaseInput name='deliveryAddress1' label='Address 1' defaultValue={values.deliveryAddress1} error={touched.deliveryAddress1 && errors.deliveryAddress1 ? errors.deliveryAddress1 : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                    <BaseInput name='deliveryAddress2' label='Address 2' optional defaultValue={values.deliveryAddress2} error={touched.deliveryAddress2 && errors.deliveryAddress2 ? errors.deliveryAddress2 : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                    <BaseInput name='deliveryCity' label='City' defaultValue={values.deliveryCity} error={touched.deliveryCity && errors.deliveryCity ? errors.deliveryCity : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                    <BaseInput name='deliveryState' label='State' defaultValue={values.deliveryState} error={touched.deliveryState && errors.deliveryState ? errors.deliveryState : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                    <BaseInput name='deliveryZip' label='Zip' defaultValue={values.deliveryZip} error={touched.deliveryZip && errors.deliveryZip ? errors.deliveryZip : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                </div>
                            </div>
                        </>
                    )}
                </Formik>
            )}
            {/* Rooms + Cabinets == 4 */}
            {step === 4 && draftOrder && (
                <>
                    <div className='ui-row' style={{ justifyContent: 'space-between' }}>
                        <Stack direction='row' spacing={1}>
                            {!!draftOrder.rooms && draftOrder.rooms?.length > 0 && draftOrder.rooms.map((room) => (
                                <RoomChip key={room.key} room={room} onClick={(room) => setCurrentRoom(room.key)} selected={room.key === currentRoom} />
                            ))}
                        </Stack>
                        <WizardNav enableNext={true} onNext={() => console.log(draftOrder)} />
                    </div>
                    <div className='ui-row'>
                        <Card title='Create New Room' fullWidth disableLine>
                            <Box height={16} />
                            <RoomForm
                                room={draftOrder?.rooms?.find(el => el.key === currentRoom)}
                                onRoomAdded={addNewRoomToDraftOrder}
                            />
                        </Card>
                    </div>
                </>
            )}
            {/* File Uploads */}
            {step === 5 && (
                <>
                    <div className='ui-row' style={{ justifyContent: 'space-between' }}>
                        <p className='wizard-instructions'>Attach the Site Plan files and CabEntry file, if available. These can be uploaded later if they are not currently available.</p>
                        <WizardNav enableNext={true} onNext={() => { }} />
                    </div>
                    <div className='ui-row' style={{ width: '100%' }}>
                        <div className='column' style={{ width: '100%' }}>
                            <div className='grid-container'>
                                <div className='grid'>
                                    <div className='dashboard-card'>
                                        <h5 style={{ textAlign: 'center' }}>Upload site plans</h5>
                                        <FileDrop name='SitePlans' instructions='default' fullHeight accept='.pdf' filesReceived={addSitePlanFiles} />
                                        {sitePlanFiles && sitePlanFiles.map((el: File) => (
                                            <div key={el.name} className='file-list-item'>
                                                <p className='file-name'>{el.name}</p>
                                                <IconButton onClick={() => setSitePlanFiles(sitePlanFiles.filter(f => f.name !== el.name))}>
                                                    <CloseIcon fontSize='small' />
                                                </IconButton>
                                            </div>
                                        ))}
                                    </div>
                                    {/* <div className='dashboard-card'>
                                        <h5 style={{ textAlign: 'center' }}>Upload CabEntry documents</h5>
                                        <FileDrop name='CabEntry' instructions='default' fullHeight accept='.pdf' filesReceived={addCabentryFiles} />
                                        {cabEntryFiles && cabEntryFiles.map((el: File) => (
                                            <div key={el.name} className='file-list-item'>
                                                <p className='file-name'>{el.name}</p>
                                                <IconButton onClick={() => setCabEntryFiles(cabEntryFiles.filter(f => f.name !== el.name))}>
                                                    <CloseIcon fontSize='small' />
                                                </IconButton>
                                            </div>
                                        ))}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {/* Review Page */}
            {step === 6 && draftOrder && (
                <>
                    <div className='ui-row' style={{ justifyContent: 'space-between' }}>
                        <p className='wizard-instructions'>Review the Order information below. If any changes need to be made, navigate back to the corresponding sections, and then return to this Review page to submit the Order.</p>
                        <WizardNav showSubmit={true} onNext={() => { }} />
                    </div>
                    <div className='ui-row' style={{ width: '100%' }}>
                        <div className='column' style={{ width: '100%' }}>
                            <div className='grid-container'>
                                <div className='grid'>
                                    <Card title='Dealer Information'>
                                        <Table>
                                            <Row label='Dealer' value={selectedDealership?.name} />
                                            <Row label='Dealer #' value={selectedDealership?.dealerNumber} />
                                            <Row label='Location' value={selectedLocation?.name} />
                                            <Row blank />
                                            <Row label='Address' value={FormattingUtility.FormatAddressParts({ address1: selectedLocation?.address1, address2: selectedLocation?.address2 })} />
                                            <Row label='' value={FormattingUtility.FormatAddressParts({ city: selectedLocation?.city, state: selectedLocation?.state, zip: selectedLocation?.zip })} />
                                            <Row blank />
                                            <Row label='Phone' value={FormattingUtility.formatPhoneNumber(selectedLocation?.phoneNumber)} />
                                            <Row label='Fax' value={FormattingUtility.formatPhoneNumber(selectedLocation?.faxNumber)} />
                                        </Table>
                                    </Card>
                                    <Card title='Delivery Information'>
                                        <Table>
                                            <Row label='Job Name' value={draftOrder.jobName} />
                                            <Row label='PO Number' value={draftOrder.purchaseOrder} />
                                            <Row blank />
                                            <Row label='Address' value={FormattingUtility.FormatAddressParts({ address1: draftOrder?.deliveryAddress1, address2: draftOrder?.deliveryAddress2 })} />
                                            <Row label='' value={FormattingUtility.FormatAddressParts({ city: draftOrder?.deliveryCity, state: draftOrder?.deliveryState, zip: draftOrder?.deliveryZip })} />
                                            <Row blank />
                                            <Row label='Contact' value={draftOrder.deliveryContact} />
                                            <Row label='Phone' value={FormattingUtility.formatPhoneNumber(draftOrder.deliveryPhone)} />
                                            <Row label='Email' value={draftOrder.deliveryEmail} />
                                        </Table>
                                    </Card>
                                    <Card title='Site Plans'>
                                        {sitePlanFiles && sitePlanFiles.map((el: File) => (
                                            <div key={el.name}>
                                                <p className='file-name'>{el.name}</p>
                                            </div>
                                        ))}
                                        {!sitePlanFiles && (
                                            <p className='file-name'>Not available</p>
                                        )}
                                    </Card>
                                    <Card title='Rooms + Line Items' rightHeader={<Checkbox label='Create Estimate PDF' checked={createEstimate} onChange={() => setCreateEstimate(!createEstimate)} />}>
                                        <RoomsReview rooms={draftOrder.rooms || []} />
                                    </Card>
                                    {/* <Card title='CabEntry Document'>
                                        {cabEntryFiles && cabEntryFiles.map((el: File) => (
                                            <div key={el.name}>
                                                <p className='file-name'>{el.name}</p>
                                            </div>
                                        ))}
                                        {!cabEntryFiles && (
                                            <p className='file-name'>Not available</p>
                                        )}
                                    </Card> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {/* Success */}
            {step === 7 && (
                <div style={{ height: '100%', width: '100%', flex: 1, display: 'flex', }} className='column flex-center'>
                    <div className='dashboard-card flex-center' style={{ padding: '2em', width: '550px' }}>
                        <VerifiedRoundedIcon style={{ height: '5em', width: '5em', color: 'var(--app-tertiary)', marginBottom: '1em' }} />
                        <h4 style={{ marginBottom: '1em' }}>Your order is complete!</h4>
                        <p style={{ textAlign: 'center', marginBottom: '1em' }}>You will be receiving a confirmation email with order details shortly. Order information will display on orders page within 24 hours.</p>
                        <BaseButton text='Return to Dashboard' style={{ width: '300px', borderRadius: '2em' }} onClick={() => history.push(urls.dashboard)} />
                    </div>
                </div>
            )}
            {/* Wizard Progress Indicator */}
            {step !== 7 && (
                <div className='ui-row'>
                    <ProgressIndicator current={step} total={6} />
                </div>
            )}
        </BasePage>
    )
}