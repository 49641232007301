import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import * as Store from '../../store/slices'
import { BaseButton } from '../buttons'
import { BaseDialog } from '.'
import { BaseInput } from '../inputs'
import { urls } from '../../pages'
import { User } from '../../types'
import { ApiUtility, AuthUtility, Endpoints, FormattingUtility } from '../../utilities'
import { Formik } from 'formik'
import * as Yup from 'yup'

enum FormState {
    SubmitEmail = 0,
    SubmitAuthCode = 1,
}

interface ChangeEmailDialogProps {
    onClose: () => void
    open: boolean
}

const FormSchema = Yup.object().shape({
    newEmail: Yup.string().email('Invalid').required('Required'),
    confirmNewEmail: Yup.string().required('Required').when('newEmail', {
        is: (val: string) => val && val.length > 0,
        then: Yup.string().oneOf(
            [Yup.ref('newEmail')],
            'Must match New Email field.'
        )
    }),
})
const AuthCodeFormSchmea = Yup.object().shape({
    authCode: Yup.string().matches(FormattingUtility.AuthCodeRegex, 'Invalid').length(6, 'Code must be 6 digits').required('Required'),
})

function ChangeEmailDialog(props: ChangeEmailDialogProps) {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const currentUser = useAppSelector(Store.getCurrentUser)
    const [formState, setFormState] = useState<FormState>(FormState.SubmitEmail)
    const [email, setEmail] = useState<string>('')

    const submitNewEmail = async (values: { newEmail: string, confirmNewEmail: string }) => {
        if (!currentUser) {
            history.push(urls.error)
            return
        }

        if (values.confirmNewEmail === currentUser.email) {
            alert('New email address may not equal your old email address.')
            return
        }

        await ApiUtility.Delay(1500)

        const result = await AuthUtility.ChangeEmail(values.newEmail)

        if (!result.success) {
            alert(result.errorCode === 'AliasExistsException' ? 'The given email address is already in use.' : 'Something went wrong while trying to update your email. Please contact an admin for assistance.')
            return
        }

        setEmail(values.confirmNewEmail)
        setFormState(FormState.SubmitAuthCode)
    }

    const verifyNewEmail = async (values: {authCode: string}) => {
        const verify = await AuthUtility.VerifyEmail(values.authCode)
        if (!verify.success || !email || !FormattingUtility.isValidEmail(email)) {
            alert('Something went wrong while trying to update your email address. Please contact an admin for assistance.')
            return
        }

        const response = await ApiUtility.Post(Endpoints.ProfileEmail, { email })
        if (!response.success) {
            alert('Failed to update your email address. Please contact an admin for assistance.')
            return
        }

        await ApiUtility.Delay(2000)

        dispatch(Store.setCurrentUser(response.data as User))

        props.onClose()
        setEmail('')
        setFormState(FormState.SubmitEmail)
    }

    useEffect(() => {
        setFormState(FormState.SubmitEmail)
        return () => {
            setFormState(FormState.SubmitEmail)
        }
    }, [props.open])

    return (
        <BaseDialog
            cancellable={true}
            onClose={props.onClose}
            isOpen={props.open}
            heading='Change Email'
        >
            <div style={{ width: 350 }}>
                {formState === FormState.SubmitEmail ? (
                    <Formik
                        initialValues={{ newEmail: '', confirmNewEmail: '' }}
                        validateOnMount
                        validationSchema={FormSchema}
                        onSubmit={submitNewEmail}
                    >
                        {({ values, errors, touched, dirty, isValid, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit}>
                                <BaseInput key='newEmail' name='newEmail' label='New Email' defaultValue={values.newEmail} error={touched.newEmail && errors.newEmail ? errors.newEmail : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                <BaseInput key='confirmNewEmail' name='confirmNewEmail' label='Confirm New Email' defaultValue={values.confirmNewEmail} error={touched.confirmNewEmail && errors.confirmNewEmail ? errors.confirmNewEmail : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <BaseButton text='Submit' rightIcon='arrowRight' disabled={!dirty || !isValid} type='submit' loading={isSubmitting} />
                                </div>
                            </form>
                        )}
                    </Formik>
                ) : (
                    <Formik
                        initialValues={{ authCode: '' }}
                        validateOnMount
                        validationSchema={AuthCodeFormSchmea}
                        onSubmit={verifyNewEmail}
                    >
                        {({ values, errors, touched, dirty, isValid, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit}>
                                <BaseInput key='authCode' name='authCode' label='Verification Code' defaultValue={values.authCode} value={values.authCode} error={touched.authCode && errors.authCode ? errors.authCode : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} disabled={isSubmitting} />
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <BaseButton text='Submit' rightIcon='arrowRight' disabled={!dirty || !isValid} type='submit' loading={isSubmitting} />
                                </div>
                            </form>
                        )}
                    </Formik>
                )}
            </div>
        </BaseDialog>
    )
}

export default ChangeEmailDialog