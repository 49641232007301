import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
//Slices
import appReducer from './slices/app'
import dealershipReducer from './slices/dealership'
import messagesReducer from './slices/messages'
import notificationsReducer from './slices/notifications'
import ordersReducer from './slices/orders'
import productsReducer from './slices/products'
import userReducer from './slices/users'

const reducers = combineReducers({
    app: appReducer,
    dealerships: dealershipReducer,
    messages: messagesReducer,
    notifications: notificationsReducer,
    orders: ordersReducer,
    products: productsReducer,
    users: userReducer,
})

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>