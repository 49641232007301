import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import * as Store from '../store/slices'
import { BaseButton } from '../components/buttons'
import { BaseDialog } from '../components/dialogs'
import { CreateUser, EditLocation, LocationForm } from '../components/forms'
import { BaseInput } from '../components/inputs'
import { BaseGrid, BasePage, Card } from '../components/layout'
import { Dealership, Location, User } from '../types'
import { UserRoles, UserStatus } from '../types/enums'
import { DealershipSchema } from '../types/schemas'
import { ApiUtility, Endpoints, FormattingUtility } from '../utilities'
import { Formik } from 'formik'
import { GridColDef } from '@mui/x-data-grid'

type LoadingStates = {
    users: boolean
    invitations: boolean
}

const UserColumns: GridColDef[] = [
    { field: 'firstName', headerName: 'Name', flex: 1, valueGetter: ({ row }) => row.firstName + ' ' + row.lastName },
    { field: 'email', headerName: 'Email', flex: 2, },
    { field: 'idLocations', headerName: 'Location', flex: 1, valueGetter: ({ row }) => (row.location && row.location.name) || '' },
    { field: 'role', headerName: 'User Type', flex: 1, valueGetter: ({ row }) => FormattingUtility.getUserRole(row.role) },
    { field: 'status', headerName: 'Status', flex: 1, valueGetter: ({ row }) => !!row.authId ? FormattingUtility.getUserStatus(row.status) : 'Invited' },
]

const LocationColumns: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 1, },
    { field: 'address', headerName: 'Address', flex: 2, valueGetter: ({ row }) => FormattingUtility.FormatAddressParts(row) },
]

export default function Page() {
    const dispatch = useAppDispatch()
    const currentUser = useAppSelector(Store.getCurrentUser)
    const [loading, setLoading] = useState<LoadingStates>({ users: false, invitations: false })
    const [currentDealership, setSelectedDealership] = useState<Dealership | null>(null)
    const [selectedLocation, setSelectedLocation] = useState<Location | null>(null)
    const [isAddLocationOpen, toggleIsAddLocationOpen] = useState<boolean>(false)
    const [isInviteUserOpen, toggleIsInviteUserOpen] = useState<boolean>(false)
    const [users, setUsers] = useState<any[]>([])

    const loadData = async (force: boolean = false) => {
        setSelectedDealership(currentUser?.dealership || null)
        await loadSupplementaryData(currentUser?.dealership)
    }

    const loadSupplementaryData = async (dealership?: Dealership) => {
        if (!currentUser || !dealership) {
            return
        }

        // 0. Load Users and Invitations for this Dealership
        let users: any[] = []
        const query = [UserRoles.Admin, UserRoles.Accountant, UserRoles.CustomerService, UserRoles.FieldRep].includes(currentUser.role) ? '?idDealerships=' + dealership.idDealerships : ''
        const usersResponse = await ApiUtility.Get(Endpoints.Users + query)
        if (usersResponse.success) {
            users = usersResponse.data as User[]
        }

        const invitedUsersResponse = await ApiUtility.Get(Endpoints.InvitedUsers + query)
        if (invitedUsersResponse.success) {
            for (let invitation of invitedUsersResponse.data) {
                users.push(invitation)
            }
        }

        setUsers(users)

        setLoading(prev => ({
            ...prev,
            users: false,
            invitations: false,
        }))
    }

    const editDealership = async (values: Partial<Dealership>) => {
        let response = await ApiUtility.Post(Endpoints.Dealerships, values)

        if (response.success) {
            // update dealership in redux store
            // update dealership in local state
            // disable update button
            setSelectedDealership(response.data)
            alert('Success.')
        } else {
            alert('There was a problem')
            // make the card line red !
        }
    }

    const submitNewLocation = async (values: any) => {
        // Validate states
        if (!currentDealership) {
            alert('Unexpected Error: Invalid Page State.')
            return
        }

        // Confirm
        if (!window.confirm('Are you sure that you would like to add a new location to this dealership?')) {
            return
        }

        let success = await createLocation(values)

        if (!success) {
            return
        }

        await loadData(true)
        await loadSupplementaryData(currentDealership)
        alert('Success!')
        toggleIsAddLocationOpen(false)
    }

    const createLocation = async (location: any): Promise<boolean> => {
        // Validate states
        if (!currentDealership) {
            alert('Unexpected Error: Invalid Page State.')
            return false
        }

        // Create Location
        const locResponse = await ApiUtility.Put(Endpoints.Locations, { 
            ...location, 
            idDealerships: currentDealership.idDealerships,
        })
        if (!locResponse.success) {
            alert(`There was an unexpected error while trying to set up a location: ${location.name}. Please contact an Admin for assistance.`)
            return false
        }

        return true
    }

    const invitationSubmitted = async () => {
        setLoading({
            users: true,
            invitations: true,
        })

        loadSupplementaryData(currentDealership!)

        setLoading({
            users: false,
            invitations: false,
        })

        toggleIsInviteUserOpen(false)
    }

    const reloadPage = async () => {
        let response = await ApiUtility.Get(Endpoints.Profile)

        let user = response.data as User
        
        dispatch(Store.setCurrentUser(user))
        dispatch(Store.setDealerships([user.dealership]))
        
        setSelectedLocation(null)
        setSelectedDealership(user.dealership)
    }

    useEffect(() => {
        loadData(true)
    }, [])

    return (
        <BasePage title={currentDealership ? currentDealership.name : 'Error'} requireAuth={true}>
            {!!currentDealership && (
                <>
                    <div className='ui-row grid-container'>
                        <div className='grid'>
                            <Formik
                                initialValues={currentDealership}
                                validateOnMount={true}
                                validationSchema={DealershipSchema}
                                onSubmit={values => editDealership(values)}
                            >
                                {({ dirty, values, touched, errors, isSubmitting, handleSubmit, handleBlur, handleChange }) => (
                                    <Card
                                        title='Dealership Information'
                                        rightHeader={<BaseButton text='Update' rightIcon='arrowRight' disabled={!dirty} loading={isSubmitting} onClick={handleSubmit} type='submit' />}
                                    >
                                        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                            <BaseInput name='name' label='Business Name' defaultValue={values.name} error={touched.name && errors.name ? errors.name : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                            <BaseInput name='businessType' label='Business Type' defaultValue={values.businessType} error={touched.businessType && errors.businessType ? errors.businessType : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                            <BaseInput name='phoneNumber' label='Phone Number' defaultValue={values.phoneNumber} error={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                            <BaseInput name='faxNumber' label='Fax Number' optional defaultValue={values.faxNumber} error={touched.faxNumber && errors.faxNumber ? errors.faxNumber : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                        </form>
                                    </Card>
                                )}
                            </Formik>
                            <Card
                                title='Locations'
                                error={!!currentDealership && currentDealership.locations.length < 1}
                                rightHeader={<BaseButton text='Add New' leftIcon='add' type='submit' onClick={() => toggleIsAddLocationOpen(true)} />}
                            >
                                {currentDealership.locations && currentDealership.locations.length > 0 && (
                                    <BaseGrid
                                        columns={LocationColumns}
                                        data={currentDealership.locations}
                                        hideFooter={true}
                                        idKey='idLocations'
                                        onRowClick={({ row }) => setSelectedLocation(row as Location)}
                                    />
                                )}
                            </Card>
                        </div>
                    </div>
                    <div className='ui-row'>
                        <Card title='Users'
                            fullWidth
                            loading={loading.users}
                            error={users.length < 1}
                            rightHeader={<BaseButton text='Invite User' onClick={() => toggleIsInviteUserOpen(true)} leftIcon='add' type='submit' />}
                        >
                            <BaseGrid
                                columns={UserColumns}
                                data={users}
                                idKey='email'
                            />
                        </Card>
                    </div>
                </>
            )}
            <BaseDialog
                isOpen={isAddLocationOpen}
                cancellable={true}
                onClose={() => toggleIsAddLocationOpen(false)}
                heading={'Create New Location'}
            >
                <div style={{ maxHeight: '500px' }}>
                    <p className='subtle' style={{ marginBottom: '1em' }}>Complete all fields and click Submit at the bottom.</p>
                    <LocationForm 
                        existingUsers={!!users ? users.filter(el => el.status === UserStatus.Active && [UserRoles.Dealer, UserRoles.LocationManager, UserRoles.LocationBillingContact].includes(el.role)).map(el => ({key: el.authId, label: FormattingUtility.formatUserFullName(el)})) : []}
                        onSubmit={submitNewLocation} 
                    />
                    <div style={{ height: '2em' }} />
                </div>
            </BaseDialog>
            <BaseDialog
                isOpen={!!selectedLocation}
                cancellable={true}
                onClose={() => setSelectedLocation(null)}
                heading={'Edit Location'}
            >
                <div style={{ maxHeight: '500px' }}>
                    <p className='subtle' style={{ marginBottom: '1em' }}>Complete all fields and click Submit at the bottom.</p>
                    <EditLocation location={selectedLocation!} onSubmit={reloadPage} />
                    <div style={{ height: '2em' }} />
                </div>
            </BaseDialog>
            <BaseDialog
                isOpen={isInviteUserOpen}
                cancellable={true}
                onClose={() => toggleIsInviteUserOpen(false)}
                heading={'Invite User'}
            >
                <div style={{ maxHeight: '500px' }}>
                    <p className='subtle' style={{ marginBottom: '1em' }}>Complete all fields and click Submit at the bottom.</p>
                    <CreateUser
                        onSubmit={invitationSubmitted}
                        dealership={currentDealership!}
                        roles={[UserRoles.LocationManager, UserRoles.LocationBillingContact, UserRoles.Designer]}
                    />
                    <div style={{ height: '2em' }} />
                </div>
            </BaseDialog>
        </BasePage>
    );
}