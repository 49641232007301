import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import * as Store from '../../store/slices'
import { BaseButton } from '../buttons'
import { BaseDialog } from '.'
import { BaseInput, PhoneInput } from '../inputs'
import { urls } from '../../pages'
import { User } from '../../types'
import { ApiUtility, AuthUtility, Endpoints, FormattingUtility } from '../../utilities'
import { Formik } from 'formik'
import * as Yup from 'yup'

interface ChangeEmailDialogProps {
    onClose: () => void
    open: boolean
}

const PhoneFormSchema = Yup.object().shape({
    newPhone: Yup.string().matches(FormattingUtility.PhoneNumberRegex, 'Invalid').required('Required'),
})
const AuthCodeFormSchmea = Yup.object().shape({
    authCode: Yup.string().matches(FormattingUtility.AuthCodeRegex, 'Invalid').length(6, 'Code must be 6 digits').required('Required'),
})

function ChangePhoneDialog(props: ChangeEmailDialogProps) {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const currentUser = useAppSelector(Store.getCurrentUser)
    const [phoneNumber, setPhoneNumber] = useState<string>('')

    const submitNewPhoneNumber = async (values: { newPhone: string }, { resetForm }: any) => {
        if (!currentUser) {
            history.push(urls.error)
            return
        }

        resetForm({})

        let phone = FormattingUtility.unformatPhoneNumber(values.newPhone)
        if (phone === currentUser.phoneNumber) {
            alert('New phone number may not equal your old phone number.')
            return
        }
        
        await ApiUtility.Delay(1500)

        const result = await AuthUtility.ChangePhone(phone)
        if (!result.success) {
            alert('Something went wrong while trying to update your phone number.')
            return
        }

        setPhoneNumber(phone)
    }

    return (
        <BaseDialog
            cancellable={true}
            onClose={props.onClose}
            isOpen={props.open}
            heading='Change Cell Phone'
        >
            <div style={{ width: 300 }}>
                {phoneNumber === '' ?
                    <Formik
                        initialValues={{ newPhone: '' }}
                        validationSchema={PhoneFormSchema}
                        onSubmit={submitNewPhoneNumber}
                    >
                        {({ values, errors, touched, dirty, isValid, isSubmitting, handleBlur, handleChange, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <PhoneInput name='newPhone' label='New Phone Number' defaultValue={values.newPhone} fullWidth={true} error={touched.newPhone && errors.newPhone ? errors.newPhone : ''} onBlur={handleBlur} onChange={handleChange} />
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <BaseButton text='Update' rightIcon='arrowRight' type='submit' disabled={!dirty || !isValid} loading={isSubmitting} />
                                </div>
                            </form>
                        )}
                    </Formik>
                    :
                    <Formik
                        initialValues={{ authCode: '' }}
                        validationSchema={AuthCodeFormSchmea}
                        onSubmit={async (values) => {
                            const success = await AuthUtility.VerifyPhone(values.authCode)
                            if (!success || !phoneNumber || !FormattingUtility.isValidPhoneNumber(phoneNumber)) {
                                alert('Something went wrong while trying to update your phone number. Please contact an admin for assistance.')
                                return
                            }

                            const response = await ApiUtility.Post(Endpoints.ProfilePhone, { phoneNumber })
                            if (!response.success) {
                                alert('Failed to update your phone number. Please contact an admin for assistance.')
                                return
                            }

                            dispatch(Store.setCurrentUser(response.data as User))

                            props.onClose()
                            setPhoneNumber('')
                        }}
                    >
                        {({ values, errors, touched, dirty, isValid, isSubmitting, handleBlur, handleChange, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <BaseInput name='authCode' label='Verification Code' value={values.authCode} error={touched.authCode && errors.authCode ? errors.authCode : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} disabled={isSubmitting} />
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <BaseButton text='Submit' rightIcon='arrowRight' disabled={!dirty || !isValid} type='submit' loading={isSubmitting} />
                                </div>
                            </form>
                        )}
                    </Formik>
                }
            </div>
        </BaseDialog>
    )
}

export default ChangePhoneDialog