import { CircularProgress } from '@mui/material'
import { ReactNode } from 'react'

type CardProps = {
    children: ReactNode
    disableLine?: boolean
    error?: boolean
    fullWidth?: boolean
    loading?: boolean
    maxHeight?: string
    rightHeader?: ReactNode
    title: string
}

export default function Card(props: CardProps) {
    return (
        <div className='dashboard-card' style={props.fullWidth ? { width: '100%' } : {}}>
            <div className='row' style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <h5>{props.title}</h5>
                <span>
                    {props.rightHeader}
                </span>
            </div>
            {!props.disableLine && (
                <div className='line' style={!props.loading && props.error ? { backgroundColor: 'red' } : {}} />
            )}
            {props.loading && (
                <div className='row flex-center' style={{ height: '100%' }}>
                    <CircularProgress style={{ color: 'var(--ui-ma-blue) ' }} />
                </div>
            )}
            {!props.loading && (
                <div style={{ maxHeight: props.maxHeight || '1000px' }}>
                    {props.children}
                </div>
            )}
        </div>
    )
}