import readXlsxFile from 'read-excel-file'
import { Product } from '../types'

class utility {
    getProducts = async (file: File): Promise<Partial<Product>[]> => {
        let products: Partial<Product>[] = []
        await readXlsxFile(file).then((rows) => {
            // Note: Start with i=1 to skip header row
            for (let i = 1; i < rows.length; i++) {
                const row = rows[i]

                if (row[5].toString() !== 'PARSED') {
                    continue
                }
                
                let obj: Partial<Product> = {
                    sku: row[0].toString(),
                    description: row[1].toString(),
                    width: row[2].toString(),
                    height: row[3].toString(),
                    depth: row[4].toString(),
                }
                    
                products.push(obj)
            }
        })

        return products
    }
}

export default utility