import { ReactNode, useEffect, useState, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import * as Store from '../store/slices'
import { BaseButton } from '../components/buttons'
import { BaseDialog } from '../components/dialogs'
import { CreateUser, EditLocation, LocationForm } from '../components/forms'
import { BaseInput } from '../components/inputs'
import { BaseGrid, BasePage, Card } from '../components/layout'
import CreateDealershipWizard from '../components/wizards/CreateDealershipWizard'
import { Dealership, Location, User, Order } from '../types'
import { OrderStatus, UserRoles, UserStatus } from '../types/enums'
import { DealershipSchema } from '../types/schemas'
import { ApiUtility, Endpoints, FormattingUtility } from '../utilities'
import { Formik } from 'formik'
import { DateTime } from 'luxon'
import { GridColDef } from '@mui/x-data-grid'
import { urls } from '.'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'

type LoadingStates = {
    users: boolean
    invitations: boolean
}

enum UIState {
    List = 1,
    ViewDetails = 2,
    Create = 3,
}

const UserColumns: GridColDef[] = [
    { field: 'firstName', headerName: 'Name', flex: 1, valueGetter: ({ row }) => row.firstName + ' ' + row.lastName },
    { field: 'email', headerName: 'Email', flex: 2, },
    { field: 'idLocations', headerName: 'Location', flex: 1, valueGetter: ({ row }) => (row.location && row.location.name) || '' },
    { field: 'role', headerName: 'User Type', flex: 1, valueGetter: ({ row }) => FormattingUtility.getUserRole(row.role) },
    { field: 'status', headerName: 'Status', flex: 1, valueGetter: ({ row }) => !!row.authId ? FormattingUtility.getUserStatus(row.status) : 'Invited' },
]

const LocationColumns: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 1, },
    { field: 'address', headerName: 'Address', flex: 2, valueGetter: ({ row }) => FormattingUtility.FormatAddressParts(row) },
]

export default function Page() {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const orders = useAppSelector(Store.getOrders)
    const currentUser = useAppSelector(Store.getCurrentUser)
    const dealerships = useAppSelector<Dealership[]>(Store.getDealerships)
    const [loading, setLoading] = useState<LoadingStates>({ users: false, invitations: false })
    const [selectedDealership, setSelectedDealership] = useState<Dealership | null>(null)
    const [selectedLocation, setSelectedLocation] = useState<Location | null>(null)
    const [state, setState] = useState<UIState>(UIState.List)
    const [isAddLocationOpen, toggleIsAddLocationOpen] = useState<boolean>(false)
    const [isInviteUserOpen, toggleIsInviteUserOpen] = useState<boolean>(false)
    const [users, setUsers] = useState<any[]>([])

    const Columns: GridColDef[] = [
        { field: 'name', headerName: 'Name', flex: 1, },
        { field: 'dealerNumber', headerName: 'Dealer Number', flex: 1, },
        { field: 'businessType', headerName: 'Business Type', flex: 1, },
        { field: '_address', headerName: 'Address', flex: 1, valueGetter: (rowParams) => FormattingUtility.FormatAddressParts(rowParams.row) },
        { field: 'created', headerName: 'Created', flex: 1, valueGetter: (rowParams) => DateTime.fromISO(rowParams.row.created).toFormat('MMMM d, yyyy') },
    ]

    const OrderColumnDefinitions: GridColDef[] = [
        { field: 'created', headerName: 'Date', flex: 1, valueGetter: ({ row }) => FormattingUtility.formatISODate(row.created) },
        { field: 'status', headerName: 'Status', flex: 1, renderCell: ({ row }) => getPrettyStatus(row.status) },
        { field: 'deliveryDate', headerName: 'Delivery Date', flex: 1, valueGetter: ({ row }) => FormattingUtility.formatISODate(row.deliveryDate) },
        { field: 'listPrice', headerName: 'List Price', flex: 1, valueGetter: ({ row }) => FormattingUtility.formatMoney(row.listPrice, '-') },
        { field: 'notes', headerName: 'Notes', flex: 1, valueGetter: ({ row }) => !!row.notes ? 'Yes' : '-' },
        { field: 'jobName', headerName: 'Job Name', flex: 1, },
        { field: 'jobNumber', headerName: 'Job Number', flex: 1 },
        {
            field: '',
            headerName: '',
            sortable: false,
            width: 30,
            renderCell: ({ row }) => {
                if ( currentUser?.role === UserRoles.Admin ) {
                    return (
                        <span className='row flex-center' style={{ width: '100%' }}>
                            <DeleteForeverRoundedIcon onClick={(e: MouseEvent): void => {
                                                                e.stopPropagation()
                                                                deleteOrder(row)}} />
                        </span>
                    )
                }
            }
        },
    ]

    const getPrettyStatus = (value: OrderStatus): ReactNode => {
        return (
            <span className='row' style={{ width: '100%' }}>
                <p style={{ color: FormattingUtility.getOrderStatusColor(value), fontWeight: 500 }}>{FormattingUtility.getOrderStatus(value)}</p>
            </span>
        )
    }

    const loadData = async (force: boolean = false) => {
        if (!force && dealerships && dealerships.length > 0) {
            return
        }

        const response = await ApiUtility.Get(Endpoints.Dealerships)
        if (!response.success) {
            alert('There was a problem getting the dealerships list.')
            return
        }

        dispatch(Store.setDealerships(response.data))
    }

    const showDealership = (dealership: Dealership) => {
        setSelectedDealership(dealership)
        setState(UIState.ViewDetails)
        setLoading({
            users: true,
            invitations: true,
        })

        loadSupplementaryData(dealership)
    }

    const showList = () => {
        setSelectedDealership(null)
        setSelectedLocation(null)
        setState(UIState.List)
    }

    const loadSupplementaryData = async (dealership: Dealership) => {
        if (!currentUser || !dealership) {
            return
        }

        // 0. Load Users and Invitations for this Dealership
        let users: any[] = []
        const query = [UserRoles.Admin, UserRoles.Accountant, UserRoles.CustomerService, UserRoles.FieldRep].includes(currentUser.role) ? '?idDealerships=' + dealership.idDealerships : ''
        const usersResponse = await ApiUtility.Get(Endpoints.Users + query)
        if (usersResponse.success) {
            users = usersResponse.data as User[]
        }

        const invitedUsersResponse = await ApiUtility.Get(Endpoints.InvitedUsers + query)
        if (invitedUsersResponse.success) {
            for (let invitation of invitedUsersResponse.data) {
                users.push(invitation)
            }
        }

        setUsers(users)

        setLoading(prev => ({
            ...prev,
            users: false,
            invitations: false,
        }))
    }

    const editDealership = async (values: Partial<Dealership>) => {
        let response = await ApiUtility.Post(Endpoints.Dealerships, values)

        if (response.success) {
            // update dealership in redux store
            // update dealership in local state
            // disable update button
            setSelectedDealership(response.data)
            alert('Success.')
        } else {
            alert('There was a problem')
            // make the card line red !
        }
    }

    const submitNewLocation = async (values: any) => {
        // Validate states
        if (!selectedDealership) {
            alert('Unexpected Error: Invalid Page State.')
            return
        }

        // Confirm
        if (!window.confirm('Are you sure that you would like to add a new location to this dealership?')) {
            return
        }

        let success = await createLocation(values)

        if (!success) {
            return
        }

        await loadData(true)
        await loadSupplementaryData(selectedDealership)
        alert('Success!')
        toggleIsAddLocationOpen(false)
    }

    const createLocation = async (location: any): Promise<boolean> => {
        // Validate states
        if (!selectedDealership) {
            alert('Unexpected Error: Invalid Page State.')
            return false
        }

        // Create Location
        const locResponse = await ApiUtility.Put(Endpoints.Locations, { 
            ...location, 
            idDealerships: selectedDealership.idDealerships,
        })
        if (!locResponse.success) {
            alert(`There was an unexpected error while trying to set up a location: ${location.name}. Please contact an Admin for assistance.`)
            return false
        }

        return true
    }

    const invitationSubmitted = async () => {
        setLoading({
            users: true,
            invitations: true,
        })

        loadSupplementaryData(selectedDealership!)

        setLoading({
            users: false,
            invitations: false,
        })

        toggleIsInviteUserOpen(false)
    }

    const getPageTitle = (): string => {
        switch (state) {
            case UIState.List:
                return 'Dealerships'
            case UIState.ViewDetails:
                return selectedDealership?.name || 'View Details'
            case UIState.Create:
                return 'Create Dealership'
            default:
                return 'Error'
        }
    }

    const deleteOrder = async (row: Order) => {
        if (!window.confirm('Are you sure you would like to delete this order?')) {
            return
        }
        
        // Send delete request
        const response = await ApiUtility.Delete(Endpoints.Orders, row)

        if (!response.success) {            
            alert('There was an unexpected error while trying to delete the order. Please try again.')
            return
        }

        const list = orders.filter(order => order.idOrders !== row.idOrders)
        dispatch(Store.setOrders(list))
    }

    useEffect(() => {
        setTimeout(() => {
            loadData(true)
        }, 20)
    }, [])

    return (
        <BasePage
            title={getPageTitle()}
            requireAuth={true}
            navFunction={(url: string) => {
                if (selectedDealership) {
                    showList()
                }

                history.push(url)
            }}
        >
            {state === UIState.List && (
                <div className='ui-row'>
                    <div className='dashboard-card' style={{ width: '100%' }}>
                        <div className='row' style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: '1em' }}>
                            <p className='card-header'>Active dealerships</p>
                            <BaseButton text='Add Dealership' onClick={() => { setState(UIState.Create) }} leftIcon='add' />
                        </div>
                        <BaseGrid
                            columns={Columns}
                            data={dealerships}
                            idKey='idDealerships'
                            onRowClick={({ row }) => showDealership(row as Dealership)}
                        />
                    </div>
                </div>
            )}
            {state === UIState.ViewDetails && !!selectedDealership && (
                <>
                    <div className='ui-row grid-container'>
                        <div className='grid'>
                            <Formik
                                initialValues={selectedDealership}
                                validateOnMount={true}
                                validationSchema={DealershipSchema}
                                onSubmit={values => editDealership(values)}
                            >
                                {({ dirty, values, touched, errors, isSubmitting, handleSubmit, handleBlur, handleChange }) => (
                                    <Card
                                        title='Dealership Information'
                                        rightHeader={<BaseButton text='Update' rightIcon='arrowRight' disabled={!dirty} loading={isSubmitting} onClick={handleSubmit} type='submit' />}
                                    >
                                        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                            <BaseInput name='name' label='Business Name' defaultValue={values.name} error={touched.name && errors.name ? errors.name : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                            <BaseInput name='businessType' label='Business Type' defaultValue={values.businessType} error={touched.businessType && errors.businessType ? errors.businessType : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                            <BaseInput name='phoneNumber' label='Phone Number' defaultValue={values.phoneNumber} error={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                            <BaseInput name='faxNumber' label='Fax Number' optional defaultValue={values.faxNumber} error={touched.faxNumber && errors.faxNumber ? errors.faxNumber : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                        </form>
                                    </Card>
                                )}
                            </Formik>
                            <Card
                                title='Locations'
                                error={!!selectedDealership && selectedDealership.locations.length < 1}
                                rightHeader={<BaseButton text='Add New' leftIcon='add' type='submit' onClick={() => toggleIsAddLocationOpen(true)} />}
                            >
                                {selectedDealership.locations && selectedDealership.locations.length > 0 && (
                                    <BaseGrid
                                        columns={LocationColumns}
                                        data={selectedDealership.locations}
                                        hideFooter={true}
                                        idKey='idLocations'
                                        onRowClick={({ row }) => setSelectedLocation(row as Location)}
                                    />
                                )}
                            </Card>
                        </div>
                    </div>
                    <div className='ui-row'>
                        <Card title='Users'
                            fullWidth
                            loading={loading.users}
                            error={users.length < 1}
                            rightHeader={<BaseButton text='Invite User' onClick={() => toggleIsInviteUserOpen(true)} leftIcon='add' type='submit' />}
                        >
                            <BaseGrid
                                columns={UserColumns}
                                data={users}
                                idKey='email'
                            />
                        </Card>
                    </div>
                    <div className='ui-row'>
                        <Card title='Orders'
                            fullWidth
                            error={orders.length < 1}
                        >
                            <BaseGrid
                                columns={OrderColumnDefinitions}
                                data={orders.filter(el => el.idDealerships === selectedDealership.idDealerships)}
                                idKey='idOrders'
                                onRowClick={({ row }) => history.push(urls.orders + row.uuid)}
                            />
                        </Card>
                    </div>
                    <div className='ui-row column'>
                        <p className='subtle'>Created: {FormattingUtility.formatISODate(selectedDealership.created, 't ZZZZ, MMMM d, yyyy')}</p>
                        <p className='subtle'>Last Updated: {FormattingUtility.formatISODate(selectedDealership.modified, 't ZZZZ, MMMM d, yyyy')}</p>
                    </div>
                </>
            )}
            {state === UIState.Create && (
                <CreateDealershipWizard onClose={() => setState(UIState.List)} />
            )}
            <BaseDialog
                isOpen={isAddLocationOpen}
                cancellable={true}
                onClose={() => toggleIsAddLocationOpen(false)}
                heading='Create New Location'
            >
                <div style={{ maxHeight: '500px' }}>
                    <p className='subtle' style={{ marginBottom: '1em' }}>Complete all fields and click Submit at the bottom.</p>
                    <LocationForm 
                        existingUsers={!!users ? users.filter(el => el.status === UserStatus.Active && [UserRoles.Dealer, UserRoles.LocationManager, UserRoles.LocationBillingContact].includes(el.role)).map(el => ({key: el.authId, label: FormattingUtility.formatUserFullName(el)})) : []}
                        onSubmit={submitNewLocation} 
                    />
                    <div style={{ height: '2em' }} />
                </div>
            </BaseDialog>
            <BaseDialog
                isOpen={!!selectedLocation}
                cancellable={true}
                onClose={() => setSelectedLocation(null)}
                heading={'Edit Location'}
            >
                <div style={{ maxHeight: '500px' }}>
                    <p className='subtle' style={{ marginBottom: '1em' }}>Complete all fields and click Submit at the bottom.</p>
                    <EditLocation 
                        location={selectedLocation!} 
                        onSubmit={showList} 
                    />
                    <div style={{ height: '2em' }} />
                </div>
            </BaseDialog>
            <BaseDialog
                isOpen={isInviteUserOpen}
                cancellable={true}
                onClose={() => toggleIsInviteUserOpen(false)}
                heading={'Invite User'}
            >
                <div style={{ maxHeight: '500px' }}>
                    <p className='subtle' style={{ marginBottom: '1em' }}>Complete all fields and click Update at the bottom.</p>
                    <CreateUser
                        onSubmit={invitationSubmitted}
                        dealership={selectedDealership!}
                        roles={[UserRoles.Dealer, UserRoles.LocationManager, UserRoles.LocationBillingContact, UserRoles.Designer]}
                    />
                    <div style={{ height: '2em' }} />
                </div>
            </BaseDialog>
        </BasePage>
    );
}