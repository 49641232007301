import Catalog from './catalog'
import Dashboard from './dashboard'
import Dealership from './dealership'
import Dealerships from './dealerships'
import Error from './error'
import Help from './help'
import Invitations from './invitations'
import Landing from './landing'
import Messages from './messages'
import NewOrder from './newOrder'
import Orders from './orders'
import Profile from './profile'
import Report from './report'
import Users from './users'

const urls = {
    dashboard: '/dashboard',
    dealership: '/dealership',
    dealerships: '/dealerships',
    error: '/error',
    help: '/help',
    invitations: '/invitation',
    landing: '/',
    messages: '/messages/',
    newOrder: '/new',
    orders: '/orders/',
    pastOrders: '/delivered',
    catalog: '/catalog',
    profile: '/profile',
    report: '/report',
    users: '/users',
}

export {
    Catalog,
    Dashboard,
    Dealership,
    Dealerships,
    Error,
    Help,
    Invitations,
    Landing,
    Messages,
    NewOrder,
    Orders,
    Profile,
    Report,
    Users,
    urls
}