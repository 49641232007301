import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { urls } from '.'
import { BaseButton } from '../components/buttons'
import { SideMenu } from '../components/layout'
import { AuthUtility } from '../utilities'

export default function Page() {
    let history = useHistory()
    
    useEffect(() => {
        AuthUtility.SignOut()
    }, [])

    return (
        <div className='page'>
            <SideMenu />
            <div className='page-content flex-center' style={{ height: '100%' }}>
                <h4 style={{ color: 'var(--app-text-dark)', marginBottom: '0.5em' }}>There seems to have been a problem</h4>
                <p style={{ color: 'var(--app-text-dark)', marginBottom: '1em' }}>You have been signed out for your security. If the issue persists, please contact your administrator.</p>
                <BaseButton text='Return to Login' leftIcon='arrowLeft' onClick={() => history.push(urls.landing)} />
            </div>
        </div>
    )
}