import { BaseButton } from '../buttons'
import { BaseInput, Dropdown, PhoneInput } from '../inputs'
import { LocationSchema } from '../../types/schemas'
import { Formik } from 'formik'
import { useState } from 'react'
import { Stack } from '@mui/material'
import { DropDownItem, User } from '../../types'

const blankLocation = {
    address1: '',
    address2: '',
    city: '',
    faxNumber: '',
    name: '',
    phoneNumber: '',
    state: '',
    zip: '',
}

type LocationFormProps = {
    existingUsers: DropDownItem[]
    location?: any
    onSubmit: (values: any) => void
}

export default function LocationForm(props: LocationFormProps) {
    return (
        <Formik
            initialValues={props.location || blankLocation}
            validationSchema={LocationSchema}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={props.onSubmit}
        >
            {({ values, touched, errors, dirty, isSubmitting, isValid, handleSubmit, handleBlur, handleChange, setValues }) => (
                <form onSubmit={handleSubmit}>
                    <BaseInput name='name' label='Location Name' value={values.name} error={touched.name && errors.name ? errors.name : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                    <PhoneInput name='phoneNumber' label='Phone Number' defaultValue={values.phoneNumber} error={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                    <PhoneInput name='faxNumber' label='Fax Number' optional defaultValue={values.faxNumber} error={touched.faxNumber && errors.faxNumber ? errors.faxNumber : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                    <div style={{ width: '100%', height: '2px', backgroundColor: 'var(--ui-dark-grey)', margin: '2em 0em' }}></div>
                    <BaseInput name='address1' label='Address 1' value={values.address1} error={touched.address1 && errors.address1 ? errors.address1 : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                    <BaseInput name='address2' label='Address 2' optional value={values.address2} error={touched.address2 && errors.address2 ? errors.address2 : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                    <BaseInput name='city' label='City' value={values.city} error={touched.city && errors.city ? errors.city : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                    <BaseInput name='state' label='State' value={values.state} error={touched.state && errors.state ? errors.state : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                    <BaseInput name='zip' label='Zip' value={values.zip} error={touched.zip && errors.zip ? errors.zip : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                    <BaseButton text='Save Location' rightIcon='arrowRight' disabled={!isValid || isSubmitting} loading={isSubmitting} type='submit' />
                </form>
            )}
        </Formik>
    )
}