import { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import * as Store from '../store/slices'
import { BaseButton } from '../components/buttons'
import { BaseInput, Dropdown } from '../components/inputs'
import { BasePage } from '../components/layout'
import { MessageSchema } from '../types/schemas'
import { DropDownItem } from '../types'
import { ApiUtility, Endpoints } from '../utilities'
import { Formik } from 'formik'
import LocalPostOfficeRoundedIcon from '@mui/icons-material/LocalPostOfficeRounded'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'

enum UIState {
    NewMessage = 1,
    Submitted = 2,
}

const Topics: DropDownItem[] = [
    { key: '000-000', label: 'Bug Report' },
    { key: '000-001', label: 'Feature Request' },
]

export default function Page() {
    const dispatch = useAppDispatch()
    const threads = useAppSelector(Store.getThreads)
    const currentUser = useAppSelector(Store.getCurrentUser)
    const [state, setState] = useState<UIState>(UIState.NewMessage)

    const submitMessage = async ({ topic, message }: { topic: string, message: string }) => {
        if (!topic) {
            alert('Please select a topic.')
            return
        }

        if (!message || message.length < 20) {
            alert('Please provide some more detail before submitting your request.')
            return
        }

        const body = {
            title: `[Customer Report] ${currentUser?.firstName} ${currentUser?.lastName[0]}. - ${topic}`,
            body: message
        }

        await ApiUtility.Delay(1500)

        const response = await ApiUtility.Put(Endpoints.Messages, body)
        if (!response.success) {
            alert('There was an unexpected error while trying to submit your message. Please try again.')
            return
        }

        let list = [response.data, ...threads]
        dispatch(Store.setThreads(list))

        setState(UIState.Submitted)
    }

    return (
        <BasePage title='Report a Problem' requireAuth={true}>
            {state === UIState.NewMessage && (
                <>
                    <div className='ui-row'>
                        <p className='wizard-instructions'>Use the dropdown below to select a category for the problem that you have experienced. In order to address your issue more swiftly, please provide as much information as you can.</p>
                    </div>
                    <Formik
                        initialValues={{ message: '', topic: '' }}
                        validateOnMount={true}
                        validationSchema={MessageSchema}
                        onSubmit={submitMessage}
                    >
                        {({ dirty, isValid, values, touched, errors, isSubmitting, handleSubmit, handleBlur, handleChange, setValues, setTouched, setErrors }) => (
                            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                <div className='ui-row' style={{ maxWidth: '800px' }}>
                                    <Dropdown
                                        label='Topic'
                                        fullWidth
                                        items={Topics}
                                        onSelect={(selected, item) => {
                                            setValues({ ...values, topic: item.label });
                                        }}
                                    />
                                </div>
                                <div className='ui-row' style={{ maxWidth: '800px' }}>
                                    <BaseInput name='message' label='Message' characterLimit={500} type='textarea' defaultValue={values.message} error={touched.message && errors.message ? errors.message : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                </div>
                                <div className='ui-row' style={{ maxWidth: '800px', justifyContent: 'flex-end' }}>
                                    <BaseButton text='Send' variant='tertiary' disabled={!dirty || !isValid} style={{ width: '250px' }} rightIcon='arrowRight' type='submit' loading={isSubmitting} />
                                </div>
                            </form>
                        )}
                    </Formik>
                </>
            )}
            {state === UIState.Submitted && (
                <div style={{ height: '100%', width: '100%', flex: 1, display: 'flex', }} className='column flex-center'>
                    <div className='dashboard-card flex-center' style={{ padding: '2em', width: '450px' }}>
                        <div style={{ marginBottom: '1em', display: 'flex', position: 'relative' }}>
                            <LocalPostOfficeRoundedIcon style={{ height: '6em', width: '6em', color: 'var(--app-tertiary)' }} />
                            <div style={{ backgroundColor: 'white', borderRadius: '6em', height: '4em', width: '4em', position: 'absolute', bottom: '0px', right: '0px', zIndex: 2 }}>
                                <CheckRoundedIcon style={{ height: '100%', width: '100%', color: 'var(--app-tertiary)' }} />
                            </div>
                        </div>
                        <h4 style={{ marginBottom: '1em' }}>Message sent successfully!</h4>
                        <p style={{ textAlign: 'center', marginBottom: '2em' }}>Thank you for contacting us, please allow up to 48 hours for a response. Responses will appear in your Message Center, and you will be notified via email.</p>
                        <BaseButton text='Return to Form' style={{ width: '300px' }} rounded onClick={() => setState(UIState.NewMessage)} />
                    </div>
                </div>
            )}
        </BasePage>
    );
}