import Dialog from '@mui/material/Dialog';
import { BaseButton, IconButton } from '../buttons';
import { BaseInput } from '../inputs';
import { Formik } from 'formik';
import { AuthUtility, ApiUtility, Endpoints } from '../../utilities';
import * as Yup from 'yup';

interface VerifyEmailDialogProps {
    onCancel: () => void
    onConfirm: () => void
    open: boolean
}

const FormSchema = Yup.object().shape({
    verificationCode: Yup.string().required('Required'),
})

function VerifyEmailDialog(props: VerifyEmailDialogProps) {
    return <Dialog onClose={props.onCancel} open={props.open} style={{ padding: 20 }} PaperProps={{ style: { borderRadius: 20, backgroundColor: 'var(--ui-grey)' } }}>
        <div style={{ padding: 20, width: 350, borderRadius: 'var(--rounded-borders)' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h4>Verify Email</h4>
                <IconButton icon='close' size='small' onClick={props.onCancel} />
            </div>
            <Formik
                initialValues={{ verificationCode: '' }}
                validationSchema={FormSchema}
                onSubmit={async (values) => {
                    const result = await AuthUtility.VerifyEmail(values.verificationCode)
                    if (!result.success) {
                        alert('Something went wrong while trying to verify your email.')
                        return
                    }

                    const user = await AuthUtility.GetCurrentUser()
                    // const response = await ApiUtility.Post(Endpoints.UsersEmail, { email: user.attributes.email })
                    let response = {}
                    if (response) {
                        alert('Something went wrong while trying to update your email.')
                        return
                    }
                    props.onConfirm()
                }}
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <BaseInput name='verificationCode' label='Verification Code' error={touched.verificationCode && errors.verificationCode ? errors.verificationCode : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <BaseButton text='Verify' rightIcon='arrowRight' type='submit' loading={isSubmitting} />
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    </Dialog>
}

export default VerifyEmailDialog;