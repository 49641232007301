import AddCircleIcon from '@mui/icons-material/AddCircle'
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded'
import ForumIcon from '@mui/icons-material/Forum'
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded'
import GroupRoundedIcon from '@mui/icons-material/GroupRounded'
import LabelImportantRoundedIcon from '@mui/icons-material/LabelImportantRounded';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded'
import QuizIcon from '@mui/icons-material/Quiz'
import RestoreRoundedIcon from '@mui/icons-material/RestoreRounded'
import {
    Box,
    Drawer as MuiDrawer,
    IconButton,
    List,
} from '@mui/material'
import { styled, Theme, CSSObject } from '@mui/material/styles'
import { urls } from '../../pages'
import { ReactNode, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import * as Store from '../../store/slices'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { UserRoles } from '../../types/enums'
import { AuthUtility } from '../../utilities'
import { ChevronLeftRounded } from '@mui/icons-material'
import { NavItem } from '.'

type NavItemType = {
    label: string
    icon: ReactNode
    roles?: UserRoles[]
    subroutes: boolean
    url: string
}

type SideMenuProps = {
    navFunction?: (url: string) => void
}

const MAStaffUsers = [UserRoles.Admin, UserRoles.Accountant, UserRoles.CustomerService]

const drawerWidth = 260

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        backgroundColor: 'blue',
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
)

const CollapseDrawer = (props: SideMenuProps) => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const [signedIn, setSignedIn] = useState<boolean>(false)
    const currentUser = useAppSelector(Store.getCurrentUser)
    const collapsed = useAppSelector(Store.getAppBarState)
    const pathParamPages = [urls.messages, urls.pastOrders]

    const NavItems: NavItemType[] = [
        { label: 'Dashboard', icon: <GridViewRoundedIcon />, subroutes: false, url: urls.dashboard },
        { label: 'Dealership', icon: <CorporateFareRoundedIcon />, roles: [UserRoles.Dealer], subroutes: false, url: urls.dealership },
        { label: 'Dealerships', icon: <CorporateFareRoundedIcon />, roles: MAStaffUsers, subroutes: false, url: urls.dealerships },
        { label: 'Users', icon: <GroupRoundedIcon />, roles: MAStaffUsers, subroutes: false, url: urls.users },
        { label: 'New Orders', icon: <AddCircleIcon />, subroutes: false, url: urls.newOrder },
        { label: 'View Orders', icon: <ListAltRoundedIcon />, subroutes: true, url: urls.orders },
        { label: 'Past Orders', icon: <RestoreRoundedIcon />, subroutes: false, url: urls.pastOrders },
        { label: 'Catalog', icon: <LabelImportantRoundedIcon />, roles: [UserRoles.Admin], subroutes: false, url: urls.catalog },
        { label: 'Message Center', icon: <ForumIcon />, subroutes: true, url: urls.messages },
        { label: 'Help Center', icon: <QuizIcon />, subroutes: false, url: urls.help },
    ]

    const navigate = (url: string) => {
        if (props.navFunction) {
            props.navFunction(url)
            return
        }

        history.push(url)
    }

    const isActive = (navItem: NavItemType): boolean => {
        let active = false

        if (navItem.subroutes) {
            active = history.location.pathname.startsWith(navItem.url)
        } else {
            active = history.location.pathname === navItem.url
        }

        return active
    }

    const checkAuth = async () => {
        let signedIn = await AuthUtility.IsSignedIn()

        setSignedIn(signedIn)
    }

    useEffect(() => {
        checkAuth()
    }, [history.location.pathname])

    if (!currentUser || !signedIn) {
        return (
            <div className='column' style={{ backgroundColor: 'var(--app-primary)', width: drawerWidth, height: '100%' }}>
                <div className='row flex-center' style={{ margin: '2em 0px', width: '100%' }}>
                    <img src='/images/logos/ma.svg' style={{ height: '2em' }} alt='Modern Amish' />
                </div>
            </div>
        )
    }

    return (
        <Box sx={{ display: 'flex' }}>
            {/* TODO: Implement MUI's CssBaseline */}
            {/* <CssBaseline /> */}
            <Drawer variant='permanent' open={!collapsed}>
                <Box sx={{ backgroundColor: 'var(--app-primary)', height: '100%' }}>
                    <DrawerHeader>
                        <div className='row flex-center' style={{ margin: '2em 0px', width: '100%' }} onClick={() => navigate(urls.dashboard)}>
                            <img src='/images/logos/ma.svg' style={{ height: '2em' }} alt='Modern Amish' />
                        </div>
                    </DrawerHeader>
                    <List>
                        {NavItems.map(item => (!item.roles || item.roles.includes(currentUser.role)) && (
                            <NavItem
                                key={item.url}
                                active={isActive(item)}
                                icon={item.icon}
                                label={item.label}
                                onClick={() => navigate(item.url)}
                            />
                        ))}
                    </List>

                    {!collapsed && (
                        <div style={{ marginLeft: '1em', marginTop: '2em', marginBottom: '2em', }}>
                            <h3 style={{ color: 'white', fontWeight: 700, fontSize: '1.2em' }}>Quick Links</h3>
                            <Link style={{ color: 'white', display: 'block' }} to={urls.report} >Report a problem</Link>
                            <a href='https://www.modernamishcabinetry.com' target='_blank' style={{ color: 'white', display: 'block' }}>MA Website</a>
                            {/* <a href='https://modern-amish-production.s3.us-east-2.amazonaws.com/downloads/ModernAmish-Cabentry-2022-07-06.exe' target='_blank' style={{ color: 'white', display: 'block' }}>Download CabEntry</a> */}
                        </div>
                    )}

                    {/* <IconButton onClick={() => dispatch(Store.setAppBarState(!collapsed))}>
                        <ChevronLeftRounded style={{ color: 'white' }} />
                    </IconButton> */}
                </Box>
            </Drawer>
        </Box>
    )
}

export default CollapseDrawer