import { ReactNode, useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import { IconButton } from '../buttons'

type BaseDialogProps = {
    cancellable: boolean
    children: ReactNode
    heading?: string
    isOpen: boolean
    onClose: () => void
}

export default function BaseDialog(props: BaseDialogProps) {
    const onClose = (event: any, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (props.cancellable) {
            props.onClose()
        }
    }

    useEffect(() => {
        if (props.cancellable && !props.heading) {
            throw new Error('Cancellable dialogs must be given a heading. It\'s how it has to be.')
        }
    }, [props.heading, props.cancellable])

    return (
        <Dialog onClose={onClose} open={props.isOpen} PaperProps={{ className: 'dialog-paper' }}>
            {props.cancellable &&
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                    <h4>{props.heading}</h4>
                    <IconButton icon='close' size='small' onClick={props.onClose} />
                </div>
            }
            {props.children}
        </Dialog>
    )
}