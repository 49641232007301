import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import * as Store from '../store/slices'
import { BaseButton } from '../components/buttons'
import { BaseInput, Dropdown } from '../components/inputs'
import { BaseGrid, BasePage, Confirmation } from '../components/layout'
import { Chat, MessageDrawer } from '../components/ui'
import { urls } from '.'
import { MessageSchema } from '../types/schemas'
import { DropDownItem } from '../types'
import { ApiUtility, Endpoints, FormattingUtility } from '../utilities'
import { Formik } from 'formik'
import { GridColDef } from '@mui/x-data-grid'
import { Drawer } from '@mui/material'

enum UIState {
    Threads = 0,
    NewMessage = 1,
    Submitted = 2,
}

const Topics: DropDownItem[] = [
    { key: '000-000', label: 'When can I expect my order?' },
    { key: '000-001', label: 'Cancel or update an Order' },
    { key: '000-002', label: 'Edit a location at my dealership' },
    { key: '000-003', label: 'Invite a new user to my dealership' },
    { key: '000-004', label: 'Revoke an invitation for my dealership location' },
    { key: '000-005', label: 'Delete an existing user for my dealership location' },
]

const MessageColumnDefinitions: GridColDef[] = [
    { field: 'created', headerName: 'Date', flex: 1, valueGetter: ({ row }) => FormattingUtility.formatISODate(row.created) },
    { field: 'name', headerName: 'Subject', flex: 2, },
    { field: 'lastMessage', headerName: 'Last Message', flex: 3, valueGetter: ({ row }) => row.lastMessage || '' },
]

export default function Page() {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const threads = useAppSelector(Store.getThreads)
    const currentUser = useAppSelector(Store.getCurrentUser)
    const [state, setState] = useState<UIState>(UIState.Threads)
    const [currentThread, setCurrentThread] = useState<string>()
    const [drawerOpen, toggleDrawer] = useState<boolean>(false)

    const submitMessage = async ({ topic, message }: { topic: string, message: string }) => {
        if (!topic) {
            alert('Please select a topic.')
            return
        }

        if (!message || message.length < 20) {
            alert('Please provide some more detail before submitting your request.')
            return
        }

        const body = {
            title: `[Support Request] ${currentUser?.firstName} ${currentUser?.lastName[0]}. - ${topic}`,
            body: message
        }

        await ApiUtility.Delay(1500)

        const response = await ApiUtility.Put(Endpoints.Messages, body)
        if (!response.success) {
            alert('There was an unexpected error while trying to submit your message. Please try again.')
            return
        }

        let list = [response.data, ...threads]
        dispatch(Store.setThreads(list))

        setState(UIState.Submitted)
    }

    useEffect(() => {
        if (history.location.pathname === urls.messages) {
            return
        }

        const thread = history.location.pathname.replace(urls.messages, '')
        // const thread = threads.find(el => el.uuid === uuid)

        if (thread) {
            setCurrentThread(thread)
            toggleDrawer(true)
        }
    }, [])

    return (
        <BasePage
            title='Message Center'
            requireAuth={true}
            navFunction={(url: string) => {
                if (state === UIState.NewMessage) {
                    setState(UIState.Threads)
                }
                
                if (currentThread) {
                    // setCurrentThread(null)
                    setState(UIState.Threads)
                    toggleDrawer(false)
                }

                history.push(url)
            }}
        >
            {state === UIState.Threads && (
                <>
                    <div className='ui-row'>
                        <p className='wizard-instructions'>Here you will find Order messages and support requests. If you have questions about an order, please utilize the relevant Order's thread.</p>
                    </div>
                    <div className='ui-row'>
                        <div className='dashboard-card' style={{ width: '100%' }}>
                            <div className='row' style={{ justifyContent: 'space-between' }}>
                                <p className='card-header'>Recent Messages</p>
                            </div>
                            <BaseGrid
                                columns={MessageColumnDefinitions}
                                data={threads}
                                idKey='uuid'
                                hideFooter={true}
                                onRowClick={({ row }) => {setCurrentThread(row.uuid); toggleDrawer(true)}}
                            />
                        </div>
                    </div>
                </>
            )}
            {state === UIState.NewMessage && (
                <>
                    <div className='ui-row'>
                        <p className='wizard-instructions'>Use the dropdown below to select a contact from the available list. Once contact is selected type message is content box and click "Send" when finished.</p>
                    </div>
                    <Formik
                        initialValues={{ message: '', topic: '' }}
                        validateOnMount={true}
                        validationSchema={MessageSchema}
                        onSubmit={submitMessage}
                    >
                        {({ dirty, isValid, values, touched, errors, isSubmitting, handleSubmit, handleBlur, handleChange, setValues, setTouched, setErrors }) => (
                            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                <div className='ui-row' style={{ maxWidth: '800px' }}>
                                    <Dropdown
                                        label='Topic'
                                        fullWidth
                                        items={Topics}
                                        onSelect={(selected, item) => {
                                            setValues({ ...values, topic: item.label });
                                        }}
                                    />
                                </div>
                                <div className='ui-row' style={{ maxWidth: '800px' }}>
                                    <BaseInput name='message' label='Message' type='textarea' defaultValue={values.message} error={touched.message && errors.message ? errors.message : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                </div>
                                <div className='ui-row' style={{ maxWidth: '800px', justifyContent: 'flex-end' }}>
                                    <BaseButton text='Send' variant='tertiary' disabled={!dirty || !isValid} style={{ width: '250px' }} rightIcon='arrowRight' type='submit' loading={isSubmitting} />
                                </div>
                            </form>
                        )}
                    </Formik>
                </>
            )}
            {state === UIState.Submitted && (
                <Confirmation 
                    actionText='Return to Messages'
                    message='Message sent successfully!'
                    onConfirm={() => setState(UIState.Threads)}
                    title='Thank you for contacting us, please allow up to 48 hours for a response. Responses will appear in your Message Center, and you will be notified via email.'
                />
            )}
            <MessageDrawer 
                isOpen={drawerOpen}
                onClose={() => toggleDrawer(false)}
                thread={currentThread}
            />
        </BasePage>
    );
}