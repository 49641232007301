import { useEffect } from 'react'
import { LoginForm } from '../components/forms'
import { SideMenu } from '../components/layout'
import { useAppDispatch } from '../store/hooks'
import * as Store from '../store/slices'
import { AuthUtility } from '../utilities'

export default function Page() {
    const dispatch = useAppDispatch()
    const logout = async () => {
        await AuthUtility.SignOut()
        dispatch(Store.resetDealershipSlice())
        dispatch(Store.resetMessagesSlice())
        dispatch(Store.resetNotificationsSlice())
        dispatch(Store.resetOrdersSlice())
        dispatch(Store.resetProductsSlice())
        dispatch(Store.resetUsersSlice())
    }
    
    useEffect(() => {
        logout()
    }, [])
    
    return (
        <div className='page'>
            <SideMenu />
            <div className='page-content flex-center' style={{ backgroundImage: 'url(/images/landing.png)', backgroundSize: 'cover', height: '100%' }}>
                <div className='card login-form-container'>
                    <LoginForm />
                </div>
            </div>
        </div>
    )
}