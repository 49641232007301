type ProgressIndicatorProps = {
    current: number
    total: number
}

function Bubble() {
    return <div className='progress-step'></div>
}

function Current({ n }: { n: number }) {
    return (
        <div className='progress-current'>
            <p style={{ color: 'var(--app-text-light)', fontSize: '12px', margin: 0, padding: 0 }}>{n}.</p>
        </div>
    )
}

export default function Component(props: ProgressIndicatorProps) {
    return (
        <div className='column'>
            <p style={{ fontStyle: 'italic' }}>Progress</p>
            <div className='row' style={{ alignItems: 'center' }}>
                {Array.from(Array(props.total).keys()).map((n, i) => i + 1 === props.current ? <Current key={n} n={props.current} /> : <Bubble key={n} />)}
            </div>
        </div>
    )
}