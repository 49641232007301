import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import * as Store from '../../store/slices'
import { BaseDialog } from '../dialogs'
import { urls } from '../../pages'
import { User } from '../../types'
import { UserStatus } from '../../types/enums'
import { ApiUtility, Endpoints, FormattingUtility } from '../../utilities'
import { IconButton, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

export default function ManageUserMenu({ user }: { user: User }) {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const currentUser = useAppSelector<User | null>(Store.getCurrentUser)
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null)

    // TODO: The status change functions should all be combined
    const suspendUser = async () => {
        // Derp flag
        if (user.authId === currentUser?.authId) {
            alert('You may not suspend your own account.')
            return
        }

        if (!window.confirm('Suspend user access? Are you sure that you want to immediately remove this user\'s access to the system?')) {
            return
        }

        let u = {
            ...user,
            status: UserStatus.Suspended,
        }

        let response = await ApiUtility.Post(Endpoints.UserStatus, u)
        if (!response.success) {
            alert('There was a problem.')
            return
        }

        dispatch(Store.updateUser(u))

        alert('Success.')
    }

    const reactivateUser = async () => {
        // Derp flag
        if (user.authId === currentUser?.authId) {
            alert('You may not reactivate your own account.')
            return
        }

        if (!window.confirm('Reactivate this user? Are you sure that you want to immediately re-enable this user\'s access to the system?')) {
            return
        }

        let u = {
            ...user,
            status: UserStatus.Active,
        }

        let response = await ApiUtility.Post(Endpoints.UserStatus, u)
        if (!response.success) {
            alert('There was a problem.')
            return
        }

        dispatch(Store.updateUser(u))

        alert('Success.')
    }

    const deleteUser = async () => {
        // Derp flag
        if (user.authId === currentUser?.authId) {
            alert('You may not delete your own account.')
            return
        }

        if (!window.confirm('Delete user? Are you sure that you want to immediately remove this user\'s access to the system?')) {
            return
        }

        let u = {
            ...user,
            status: UserStatus.Deleted,
        }

        let response = await ApiUtility.Post(Endpoints.UserStatus, u)
        if (!response.success) {
            alert('There was a problem.')
            return
        }

        dispatch(Store.deleteUser(u))

        alert('Success.')
    }

    const resetUserPw = async () => {
        // Derp flag
        if (user.authId === currentUser?.authId) {
            alert('You may not reset your own password from here.')
            return
        }

        if (!window.confirm('Reset user password? This will immediately sign them out of all devices, and force them to change their password on their next login.')) {
            return
        }

        let response = await ApiUtility.Post(Endpoints.AccountRecovery, { authId: user.authId })
        if (!response.success) {
            alert('There was a problem.')
            return
        }

        //TODO: Update list in Redux store

        alert('Success: the user has been emailed a recovery link. Any additional recovery operations will overwrite previous actions.')
    }

    return (
        <>
            {user.authId !== currentUser?.authId && (
                <IconButton onClick={(e) => setAnchor(e.currentTarget)}>
                    <MoreVertIcon />
                </IconButton>
            )}
            {user.authId === currentUser?.authId && (
                <IconButton onClick={(e) => history.push(urls.profile)}>
                    <OpenInNewIcon />
                </IconButton>
            )}
            <BaseDialog
                cancellable={true}
                heading={user.firstName + ' ' + user.lastName}
                isOpen={!!anchor}
                onClose={() => setAnchor(null)}
            >
                <div style={{ minWidth: '300px' }}>
                    <p>Email: {user.email}</p>
                    <p>Role: {FormattingUtility.getUserRole(user.role)}</p>
                    <div style={{ height: '1px', width: '100%', margin: '0.5em 0', backgroundColor: 'var(--ui-dark-grey)' }}></div>
                    <p>Actions</p>
                    <MenuItem onClick={resetUserPw}>
                        <p>Reset Password</p>
                    </MenuItem>
                    <MenuItem onClick={suspendUser} disabled={user.status === UserStatus.Suspended}>
                        <p>Suspend User</p>
                    </MenuItem>
                    <MenuItem onClick={reactivateUser} disabled={user.status === UserStatus.Active}>
                        <p>Re-activate User</p>
                    </MenuItem>
                    <MenuItem onClick={deleteUser}>
                        <p>Delete User</p>
                    </MenuItem>
                </div>
            </BaseDialog>
        </>
    )
}