import { FormattingUtility } from '../utilities'
import * as Yup from 'yup'

export const UserSchema = Yup.object().shape({
    email: Yup.string().email('Invalid').required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    phoneNumber: Yup.string().matches(FormattingUtility.PhoneNumberRegex, 'Invalid').required('Required'),
})

export const DealershipSchema = Yup.object().shape({
    businessType: Yup.string().required('Required'),
    faxNumber: Yup.string(),
    name: Yup.string().required('Required'),
    phoneNumber: Yup.string().matches(FormattingUtility.PhoneNumberRegex, 'Invalid').required('Required'),
})

export const LocationSchema = Yup.object().shape({
    address1: Yup.string().required('Required'),
    address2: Yup.string(),
    city: Yup.string().required('Required'),
    faxNumber: Yup.string(),
    name: Yup.string().required('Required'),
    phoneNumber: Yup.string().matches(FormattingUtility.PhoneNumberRegex, 'Invalid').required('Required'),
    state: Yup.string().length(2, '2 Letter State Required').required('Required'),
    zip: Yup.string().matches(FormattingUtility.ZipCodeRegex, 'Invalid').max(10, 'Invalid').required('Required'),
})

export const MessageSchema = Yup.object().shape({
    topic: Yup.string().required('Required'),
    message: Yup.string().required('Required').min(20),
})

export const ProductSchema = Yup.object().shape({
    sku: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    depth: Yup.string().required('Required'),
    width: Yup.string().required('Required'),
    height: Yup.string().required('Required'),
})