import { Autocomplete, TextField } from '@mui/material'
import { DropDownItem } from './Dropdown'

interface ComboboxProps {
    defaultValue: string
    items: DropDownItem[]
    label?: string
    name: string
    onSelect: (item: DropDownItem | null) => void
    placeholder?: string
}

const ComboInput = (props: ComboboxProps) => {
    // TODO: Add a hint as user types: https://mui.com/material-ui/react-autocomplete/#hint
    return (
        <Autocomplete
            value={props.items.find(item => item.label === props.defaultValue)}
            disablePortal
            id={props.name}
            options={props.items}
            onChange={(e, item) => props.onSelect(item)}
            autoComplete
            blurOnSelect
            style={{
                backgroundColor: 'white',
                border: '1px solid var(--ui-light-blue)',
                borderRadius: '0.5em',
                padding: '0px',
            }}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            getOptionKey={(option) => option.key}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={props.placeholder}
                    type='text'
                    style={{
                        border: '1px solid var(--ui-light-blue)',
                        borderRadius: '0.5em',
                        height: '2.5em',
                        backgroundColor: 'white',
                    }}
                    InputProps={{
                        ...params.InputProps,
                        style: {
                            border: 'none',
                            display: 'flex',
                            minHeight: '100%',
                            padding: '0px 5px',
                        }
                    }}
                />
            )}
        />
    )
}

export default ComboInput