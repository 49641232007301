import React, { useState } from 'react'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import VisibilityRounded from '@mui/icons-material/VisibilityRounded'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { RequiredIndicator } from '../ui'

interface PasswordInputProps {
  autoFocus?: boolean
  defaultValue?: string
  error?: string
  fullWidth?: boolean
  label?: string
  name?: string
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onChange: React.ChangeEventHandler<HTMLInputElement>
  value?: string
}

function PasswordInput(props: PasswordInputProps) {
  const [ showPassword, setPasswordVisibility ] = useState<boolean>(false);
  const label = props.label + (props.error ? ': ' + props.error : '');

  const toggleVisibility = () => {
    setPasswordVisibility(!showPassword);
  }
  const iconMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  }

  return (
    <div style={{display: 'block', marginBottom: '1em' }}>
      <p className='input-label' style={{ color: props.error ? '#FF0000' : 'var(--app-text-dark)' }}>
          {label}<RequiredIndicator />
      </p>
      <TextField
        {...props as Omit<PasswordInputProps, 'error'>}
        autoFocus={!!props.autoFocus}
        className='mui-input'
        error={!!props.error}
        fullWidth={props.fullWidth}
        InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={toggleVisibility}
                  onMouseDown={iconMouseDown}
                >
                  {showPassword
                    ? <VisibilityRounded />
                    : <VisibilityOutlinedIcon />}
                </IconButton>
              </InputAdornment>
            ),
            style: {
              display: 'flex',
              height: '100%',
              alignItems: 'center',
            }
        }}
        label=''
        margin='none'
        name={props.name || 'password'}
        onBlur={props.onBlur}
        onChange={props.onChange}
        size='medium'
        style={{
          border: '1px solid var(--ui-light-blue)',
          borderRadius: '0.5em',
          height: '2.5em',
        }}
        type={showPassword ? 'text' : 'password'}
        variant='standard'
      />
    </div>
  );
}

export default PasswordInput;