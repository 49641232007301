import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import * as Store from '../../store/slices'
import { BaseButton, LinkButton } from '../buttons'
import { BaseDialog } from '../dialogs'
import { BaseInput, PasswordInput } from '../inputs'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { urls } from '../../pages'
import { User } from '../../types'
import { ApiUtility, AuthUtility, Endpoints, FormattingUtility } from '../../utilities'
import { UserRoles, UserStatus } from '../../types/enums'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

enum FormState {
    ForgotPassword,
    ForgotPasswordConfirm,
    Login,
    MFA,
    PasswordReset,
    VerifyPhone,
}

function LoginForm() {
    const dispatch = useAppDispatch()
    const currentUser = useAppSelector(Store.getCurrentUser)
    let history = useHistory()
    const [formState, setFormState] = useState<FormState>(FormState.Login)
    const [alertMessage, setAlertMessage] = useState<string>('')
    const [loginEmail, setEmail] = useState<string>()
    const [loginPassword, setPassword] = useState<string>()

    const submitLogin = async ({ email, password }: LoginFormValues, { resetForm }: LoginActions) => {
        setEmail(email)
        setPassword(password)
        let authResponse = await AuthUtility.SignIn(email, password)
        if (authResponse.signedIn) {
            await onSignedIn()
        } else if (authResponse.invalidLogin) {
            setAlertMessage('Your email or password was incorrect.')
        } else if (authResponse.promptMfa) {
            resetForm({})
            setFormState(FormState.MFA)
        } else if (authResponse.promptNewPassword) {
            resetForm({})
            setFormState(FormState.PasswordReset)
        } else if (authResponse.unhandledResponse) {
            setAlertMessage('There was an unknown error. Please try again.')
        } else {
            setAlertMessage('There was an unknown error. Please try again.')
        }
    }

    const submitMfa = async ({ mfaCode }: MFAForm) => {
        let authResponse = await AuthUtility.ConfirmSignIn(mfaCode)
        if (!authResponse.signedIn) {
            alert('Something went wrong while confirming sign in.')
            return
        }

        await onSignedIn()
    }

    const submitMFAPhone = async (values: VerifyMFAPhoneForm) => {
        let success = await AuthUtility.VerifyPhone(values.authCode)

        if (success) {
            onSignedIn()
        } else {
            alert('Something went wrong while setting up MFA for your account. Please contact an administrator.')
            return
        }
    }

    const resendMFAPhone = async () => {
        if (!loginEmail || loginEmail === undefined || !loginPassword || loginPassword === undefined) {
            alert('Something went wrong. Please try signing in again.')
            // resetForm({})
            setFormState(FormState.Login)
            return
        }
        let success = await AuthUtility.ResendPhoneVerification()
        if (success) {
            alert('A new verification code has been sent.')
        } else {
            alert('Something went wrong while setting up MFA for your account. Please contact an administrator.')
            return
        }
    }

    const resendMFA = async () => {
        if (!loginEmail || loginEmail === undefined || !loginPassword || loginPassword === undefined) {
            alert('Something went wrong. Please try signing in again.')
            setFormState(FormState.Login)
            return
        }

        let authResponse = await AuthUtility.SignIn(loginEmail, loginPassword)
        if (authResponse.invalidLogin) {
            alert('Invalid Login')
        } else if (authResponse.promptMfa) {
            alert('A new code has been sent.')
            setFormState(formState)
        } else if (authResponse.unhandledResponse) {
            alert('There was an unknown error. Please try again.')
        } else {
            alert('There was an unknown error. Please try again.')
        }

    }

    const submitForgotPassword = async ({ email }: ForgotPwForm) => {
        let result = await AuthUtility.ForgotPassword(email)

        if (result) {
            setFormState(FormState.ForgotPasswordConfirm)
        } else {
            alert('We were unable to locate an account with that email address.')
        }
    }

    const submitForgotPasswordConfirm = async ({ authCode, email, newPassword }: ForgotPwConfirmForm) => {
        let result = await AuthUtility.ForgotPasswordConfirm(email, authCode, newPassword)

        if (result) {
            setFormState(FormState.Login)
        } else {
            alert('We were unable to update your password. Please ensure the code you entered matches the code sent to your email address.')
        }
    }

    const submitForcedPwReset = async ({ newPassword }: PwResetForm, { resetForm }: LoginActions) => {
        alert('AuthUtility.SubmitForcedPasswordReset: Notify Topher about this message ASAP')
        if (!loginEmail) {
            return
        }
        if (loginPassword && newPassword === loginPassword) {
            alert('There was a problem updating the password. Please try again.')
            return
        }

        let checkPasswordReuse = await AuthUtility.SignIn(loginEmail, newPassword)
        if (checkPasswordReuse.signedIn) {
            alert('There was a problem updating the password. Please try again.')
            return
        }

        let authResponse = await AuthUtility.CompleteNewPassword(newPassword)

        if (authResponse.promptMfa) {
            setFormState(FormState.MFA)
            return
        }

        
        // TODO: Re-enable whatever was going on here
        alert('TODO: Handle this scenario')
        //At this point, user is an invited user setting up a new account (need to turn on MFA)
        //Get profile to retrieve user's phone for MFA
        // let response = await ApiUtility.Get(Endpoints.Profile)
        // if (response.error) {
        //     alert('There was an unknown error. Please contact an administrator.')
        //     return
        // }

        // let user = new User(response)
        // dispatch(setProfile(user))

        //Turn on MFA for user
        let success = await AuthUtility.ToggleMfa('', true)

        if (success) {
            resetForm({})
            setFormState(FormState.VerifyPhone)
        } else {
            alert('There was an unknown error. Please contact an administrator.')
        }
    }

    const onSignedIn = async () => {
        // 0. Get profile info
        let response = await ApiUtility.Get(Endpoints.Profile)

        // TODO: Fix issue with suspended or deleted users seeing this message instead of the appropriate message.
        if (response.error) {
            alert('The system is currently unavailable.')
            return
        }

        let user = response.data as User

        // 0. Handle user statuses
        if (user.status === UserStatus.Suspended) {
            alert('Your account has been suspended. Please contact an administrator.')
            return
        }
        if (user.status === UserStatus.Deleted) {
            alert('There has been an error.')
            await AuthUtility.SignOut()
            //TODO: Confirm whether a user can even get to this point.
            return
        }

        // 0. Remember device
        await AuthUtility.RememberDevice()

        // 0. Set local data
        // Orders
        const ordersResponse = await ApiUtility.Get(Endpoints.Orders)
        if (ordersResponse.success) {
            dispatch(Store.setOrders(ordersResponse.data))
        }

        // Notifications
        const notificationsResponse = await ApiUtility.Get(Endpoints.Notifications)
        if (notificationsResponse.success) {
            dispatch(Store.setNotifications(notificationsResponse.data))
        }

        // Threads
        const threadsResponse = await ApiUtility.Get(Endpoints.MessageThreads)
        if (threadsResponse.success) {
            dispatch(Store.setThreads(threadsResponse.data))
        }

        // Dealerships
        if (user.role === UserRoles.Admin || user.role === UserRoles.Accountant || user.role === UserRoles.CustomerService) {
            const dealershipsResponse = await ApiUtility.Get(Endpoints.Dealerships)
            if (dealershipsResponse.success) {
                dispatch(Store.setDealerships(dealershipsResponse.data))
            }
        } else {
            dispatch(Store.setDealerships([user.dealership]))
        }

        const productsResponse = await ApiUtility.Get(Endpoints.Products)
        if (productsResponse.success) {
            dispatch(Store.setProducts(productsResponse.data))
        }

        // User profile
        dispatch(Store.setCurrentUser(user))

        // 0. Redirect to dashboard
        history.push(urls.dashboard)
    }

    const redactMFAPhone = (): string => {
        if (!currentUser) {
            return '*Error*'
        }

        return '+1 (***) ***-' + currentUser.phoneNumber.substring(currentUser.phoneNumber.length - 4)
    }

    const getForm = () => {
        switch (formState) {
            case FormState.PasswordReset: return (
                <Formik
                    initialValues={{ confirmNewPassword: '', newPassword: '' }}
                    validationSchema={PwResetSchema}
                    onSubmit={submitForcedPwReset}
                >
                    {({ values, touched, errors, isSubmitting, handleSubmit, handleBlur, handleChange }) => (
                        <form onSubmit={handleSubmit}>
                            <PasswordInput autoFocus name='newPassword' label='New password' defaultValue={values.newPassword} error={touched.newPassword && errors.newPassword ? errors.newPassword : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                            <PasswordInput name='confirmNewPassword' label='Confirm New Password' defaultValue={values.confirmNewPassword} error={touched.confirmNewPassword && errors.confirmNewPassword ? errors.confirmNewPassword : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <BaseButton text='Submit' rightIcon='arrowRight' type='submit' loading={isSubmitting} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <LinkButton text='Cancel' onClick={() => setFormState(FormState.Login)} />
                            </div>
                        </form>
                    )}
                </Formik>
            )
            case FormState.MFA: return (
                <Formik
                    initialValues={{ mfaCode: '' }}
                    validationSchema={MFASchema}
                    onSubmit={submitMfa}
                >
                    {({ values, touched, errors, isSubmitting, handleSubmit, handleBlur, handleChange }) => (
                        <form onSubmit={handleSubmit}>
                            <BaseInput autoFocus name='mfaCode' label='Verification code' defaultValue={values.mfaCode} error={touched.mfaCode && errors.mfaCode ? errors.mfaCode : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <LinkButton text='Resend Code' onClick={resendMFA} />
                                <BaseButton text='Sign In' rightIcon='arrowRight' type='submit' onClick={handleSubmit} loading={isSubmitting} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <LinkButton text='Cancel' onClick={() => setFormState(FormState.Login)} />
                            </div>
                        </form>
                    )}
                </Formik>
            )
            case FormState.VerifyPhone: return (
                <Formik
                    initialValues={{ authCode: '' }}
                    validationSchema={VerifyMFAPhoneSchema}
                    onSubmit={submitMFAPhone}
                >
                    {({ values, touched, errors, isSubmitting, handleSubmit, handleBlur, handleChange }) => (
                        <form onSubmit={handleSubmit}>
                            <p>We sent a verification code to {redactMFAPhone()}. Please enter it below.</p>
                            <BaseInput autoFocus name='authCode' label='Verify your phone' defaultValue={values.authCode} error={touched.authCode && errors.authCode ? errors.authCode : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <LinkButton text='Resend Code' onClick={resendMFAPhone} />
                                <BaseButton text='Sign In' rightIcon='arrowRight' type='submit' onClick={handleSubmit} loading={isSubmitting} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <LinkButton text='Cancel' onClick={() => setFormState(FormState.Login)} />
                            </div>
                        </form>
                    )}
                </Formik>
            )
            case FormState.ForgotPassword: return (
                <Formik
                    initialValues={{ email: '' }}
                    validationSchema={ForgotPwSchema}
                    onSubmit={submitForgotPassword}
                >
                    {({ values, touched, errors, isSubmitting, handleSubmit, handleBlur, handleChange }) => (
                        <form onSubmit={handleSubmit}>
                            <div>
                                <BaseInput autoFocus name='email' label='Email' defaultValue={values.email} error={touched.email && errors.email ? errors.email : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <LinkButton text='Cancel' onClick={() => setFormState(FormState.Login)} />
                                <BaseButton text='Send Email' rightIcon='arrowRight' type='submit' loading={isSubmitting} />
                            </div>
                        </form>
                    )}
                </Formik>
            )
            case FormState.ForgotPasswordConfirm: return (
                <Formik
                    initialValues={{ authCode: '', confirmNewPassword: '', email: '', newPassword: '' }}
                    validationSchema={ForgotPwConfirmSchema}
                    onSubmit={submitForgotPasswordConfirm}
                >
                    {({ values, touched, errors, isSubmitting, handleSubmit, handleBlur, handleChange }) => (
                        <form onSubmit={handleSubmit}>
                            <PasswordInput name='authCode' label='Code' error={touched.authCode && errors.authCode ? errors.authCode : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                            <PasswordInput name='newPassword' label='New Password' error={touched.newPassword && errors.newPassword ? errors.newPassword : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                            <PasswordInput name='confirmNewPassword' label='Confirm New Password' error={touched.confirmNewPassword && errors.confirmNewPassword ? errors.confirmNewPassword : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <LinkButton text='Cancel' onClick={() => setFormState(FormState.Login)} />
                                <BaseButton text='Submit' rightIcon='arrowRight' type='submit' loading={isSubmitting} />
                            </div>
                        </form>
                    )}
                </Formik>
            )
            case FormState.Login:
            default: return (
                <Formik
                    initialValues={{ email: '', password: '' }}
                    validationSchema={LoginSchema}
                    onSubmit={submitLogin}
                >
                    {({ values, touched, errors, isSubmitting, handleSubmit, handleBlur, handleChange }) => (
                        <form onSubmit={handleSubmit}>
                            <BaseInput name='email' label='Email' error={touched.email && errors.email ? errors.email : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} value={values.email} />
                            <PasswordInput name='password' label='Password' error={touched.password && errors.password ? errors.password : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} value={values.password} />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <LinkButton text='Forgot Password?' onClick={() => setFormState(FormState.ForgotPassword)} />
                                <BaseButton text='Sign in' rightIcon='arrowRight' style={{ width: '180px' }} type='submit' loading={isSubmitting} />
                            </div>
                        </form>
                    )}
                </Formik>
            )
        }
    }

    return (
        <>
            {getForm()}
            <BaseDialog cancellable={false} isOpen={!!alertMessage} onClose={() => { }}>
                <div className='column flex-center'>
                    <WarningAmberIcon style={{ height: '4em', width: '4em', color: 'var(--ui-red)', marginBottom: '1em' }} />
                    <p style={{ marginBottom: '1em' }}>{alertMessage}</p>
                    <BaseButton text='Okay' rightIcon='arrowRight' onClick={() => setAlertMessage('')} />
                </div>
            </BaseDialog>
        </>
    )
}

export default LoginForm

type ForgotPwForm = {
    email: string
}

type ForgotPwConfirmForm = {
    authCode: string
    confirmNewPassword: string
    email: string
    newPassword: string
}

type LoginFormValues = {
    email: string
    password: string
}

type MFAForm = {
    mfaCode: string
}

type VerifyMFAPhoneForm = {
    authCode: string
}

type PwResetForm = {
    confirmNewPassword: string
    newPassword: string
}

type LoginActions = {
    resetForm: Function
}

const ForgotPwSchema = Yup.object().shape({
    email: Yup.string().email('Invalid').required('Required'),
})

const ForgotPwConfirmSchema = Yup.object().shape({
    authCode: Yup.string().matches(FormattingUtility.AuthCodeRegex, 'Invalid').length(6, 'Too short').required('Required'),
    confirmNewPassword: Yup.string().required('Required').when('newPassword', {
        is: (val: string) => val && val.length > 0,
        then: Yup.string().oneOf(
            [Yup.ref('newPassword')],
            'Must match New Password'
        )
    }),
    email: Yup.string().email('Invalid').required('Required'),
    newPassword: Yup.string().min(8, 'Must be 8 characters').required('Required'),
})

const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid').required('Required'),
    password: Yup.string().min(8, 'Invalid').required('Required'),
})

const MFASchema = Yup.object().shape({
    mfaCode: Yup.string().matches(FormattingUtility.AuthCodeRegex, 'Invalid').length(6, 'Too short').required('Required'),
})

const VerifyMFAPhoneSchema = Yup.object().shape({
    authCode: Yup.string().matches(FormattingUtility.AuthCodeRegex, 'Invalid').length(6, 'Too short').required('Required'),
})

const PwResetSchema = Yup.object().shape({
    confirmNewPassword: Yup.string().required('Required').when('newPassword', {
        is: (val: string) => val && val.length > 0,
        then: Yup.string().oneOf(
            [Yup.ref('newPassword')],
            'Must match New Password'
        )
    }),
    newPassword: Yup.string().min(8, 'Must be 8 characters').required('Required'),
})