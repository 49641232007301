import { Storage } from 'aws-amplify'
import { S3FileInfo } from '../types';

const IMAGE_TYPES = ['jpg', 'jpeg', 'png']

const StorageUtility = {
    GetObject: async (key: string): Promise<string> => {
        return await Storage.get(key)
    },
    GetSignedUrl: async (url: string): Promise<string> => {
        try {
            if (!url || !url.includes('https://s3.amazonaws.com')) {
                throw new Error('Invalid URL');
            }

            let key = StorageUtility.ExtractKey(url);
            let signedUrl = await Storage.get(key);
            if (typeof(signedUrl) === 'string') {
                return signedUrl;
            }
            return '';
        } catch (error) {
            return '';
        }
    },
    ExtractKey: (url: string): string => {
        let bucketName = process.env.REACT_APP_S3_BUCKET_NAME as string;
        let idx = url.indexOf(bucketName);
        return url.substring(idx + bucketName.length + 1);
    },
    UploadObject: async (file: File, folderName: string, fileName?: string): Promise<{url: string}> => {
        try {
            if (!folderName.endsWith('/')) {
                folderName += '/'
            }
            let key = folderName + (fileName || file.name)
            let result: any = await Storage.put(key, file)

            return { url: 'https://s3.amazonaws.com/' + process.env.REACT_APP_S3_BUCKET_NAME + '/' + result.key }
        } catch (error) {
            return { url: '' }
        }
    },
    IsImageFile: (file: S3FileInfo): boolean => {
        const parts = file.name.split('.')
        if (parts.length < 2) {
            return false
        }

        return IMAGE_TYPES.includes(parts[parts.length - 1])
    }
}

export default StorageUtility;