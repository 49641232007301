import { Box } from '@mui/material'
import { Room } from '../../types'

const RoomChip = ({ onClick, room, selected }: { onClick: (room: Room) => void, room: Room, selected: boolean }) => {
    return (
        <Box style={{ borderRadius: '32px', backgroundColor: selected ? 'var(--app-primary)' : 'transparent', padding: '6px 8px', cursor: 'pointer', border: '2px solid var(--app-primary)' }} onClick={() => onClick(room)}>
            <p style={{ fontSize: '12px', textDecoration: 'underline', fontWeight: 'bold', color: selected ? 'white' : 'var(--app-primary)' }}>{room.name || 'New Room'}</p>
        </Box>
    )
}

export default RoomChip