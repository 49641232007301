import { ReactNode } from 'react'

export function Row({ label, value, blank }: { label?: string, value?: string | ReactNode, blank?: boolean }) {
    if (blank) {
        return (
            <tr>
                <td className='table-label' style={{ height: '1em' }} />
            </tr>
        )
    }

    return (
        <tr>
            <td className='table-label'>{label}</td>
            <td className='table-data'>{value}</td>
        </tr>
    )
}

export function Table({ children, twoTone }: { children: ReactNode, twoTone?: boolean }) {
    return (
        <table style={{width: '100%'}}>
            <tbody className={twoTone ? 'base-table-alternating' : 'base-table'}>
                {children}
            </tbody>
        </table>
    )
}