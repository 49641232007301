import { BaseButton } from '../buttons'
import LocalPostOfficeRoundedIcon from '@mui/icons-material/LocalPostOfficeRounded'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'

type ConfirmationProps = {
    actionText: string
    message: string
    onConfirm: () => void
    title: string
}

export default function Confirmation({ actionText, message, onConfirm, title }: ConfirmationProps) {
    return (
        <div style={{ height: '100%', width: '100%', flex: 1, display: 'flex', }} className='column flex-center'>
            <div className='dashboard-card flex-center' style={{ padding: '2em', width: '450px' }}>
                <div style={{ marginBottom: '1em', display: 'flex', position: 'relative' }}>
                    <LocalPostOfficeRoundedIcon style={{ height: '6em', width: '6em', color: 'var(--app-tertiary)' }} />
                    <div style={{ backgroundColor: 'white', borderRadius: '6em', height: '4em', width: '4em', position: 'absolute', bottom: '0px', right: '0px', zIndex: 2 }}>
                        <CheckRoundedIcon style={{ height: '100%', width: '100%', color: 'var(--app-tertiary)' }} />
                    </div>
                </div>
                <h4 style={{ marginBottom: '1em' }}>{title}</h4>
                <p style={{ textAlign: 'center', marginBottom: '2em' }}>{message}</p>
                <BaseButton text={actionText} style={{ width: '300px' }} rounded onClick={onConfirm} />
            </div>
        </div>
    )
}