import { ChangeEvent, useEffect, useState } from 'react'
import { BaseButton } from '../buttons'

type FileDropProps = {
    accept: string
    filesReceived: (files: File[]) => void
    fullHeight: boolean
    instructions?: string
    name: string
}

export default function FileDrop(props: FileDropProps) {
    const fileDropId = `file-drop-${props.name}`
    const fileInputId = `file-input-${props.name}`
    const [dragCount, setDragCount] = useState<number>(0)
    const [dragging, setDragging] = useState<boolean>(false)

    const openFilePicker = (e: any) => {
        e.preventDefault()

        let fileElement = document.getElementById(fileInputId)
        if (!fileElement) {
            return
        }

        fileElement.click()
    }

    const handleDrag = (e: DragEvent) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDragIn = (e: DragEvent) => {
        e.preventDefault()
        e.stopPropagation()

        if (!e.dataTransfer) {
            return
        }

        setDragCount(count => count + 1)

        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragging(true)
        }
    }

    const handleDragOut = (e: DragEvent) => {
        e.preventDefault()
        e.stopPropagation()

        setDragCount(count => count - 1)

        if (dragCount === 0) {
            setDragging(false)
        }
    }

    const handleDrop = (e: DragEvent) => {
        e.preventDefault()
        e.stopPropagation()

        if (!e.dataTransfer) {
            return
        }

        let list: File[] = []

        for (let i = 0; i <= e.dataTransfer.files.length; i++) {

            let file = e.dataTransfer.files.item(i)
            if (!file) {
                continue
            }
            if (!file?.name.endsWith(props.accept)) {
                alert(`Please only submit files of type ${props.accept}`)
                setDragCount(0)
                setDragging(false)
                return
            }
            if (file) {
                list.push(file)
            }
        }

        props.filesReceived(list)
        e.dataTransfer.clearData()

        setDragCount(0)
        setDragging(false)
    }

    const fileSelectionChanged = (e: ChangeEvent) => {
        let input = document.getElementById(fileInputId) as HTMLInputElement
        if (!input || !input.files || input.files.length < 1) {
            return
        }

        let list: File[] = []

        for (let i = 0; i <= input.files.length; i++) {
            let file = input.files.item(i)
            if (file) {
                list.push(file)
            }
        }

        props.filesReceived(list)
    }

    useEffect(() => {
        let div = document.getElementById(fileDropId)
        if (!div) {
            return
        }

        div.addEventListener('dragenter', handleDragIn)
        div.addEventListener('dragleave', handleDragOut)
        div.addEventListener('dragover', handleDrag)
        div.addEventListener('drop', handleDrop)

        return () => {
            let div = document.getElementById(fileDropId)
            if (!div) {
                return
            }

            div.removeEventListener('dragenter', handleDragIn)
            div.removeEventListener('dragleave', handleDragOut)
            div.removeEventListener('dragover', handleDrag)
            div.removeEventListener('drop', handleDrop)
        }
    }, [])

    return (
        <div className='fd-container'>
            <div id={fileDropId} className={`${props.fullHeight ? 'fd-area' : 'fd-area-compressed'} ${dragging ? 'fd-active' : ''}`}>
                <input type='file' id={fileInputId} accept={props.accept} multiple style={{ display: 'none' }} onChange={fileSelectionChanged} />
                {props.instructions &&
                    <p className='fd-instruction'>{props.instructions === 'default' ? 'Click Browse or drag files right into the Upload Area.' : props.instructions}</p>
                }
                <BaseButton text='Browse' className='base-button' onClick={openFilePicker} />
            </div>
        </div>
    )
}