import { Checkbox as MuiCheckbox, FormControlLabel, styled, Stack } from '@mui/material'
import React from 'react'

interface CheckboxProps {
  defaultValue?: boolean
  checked?: boolean
  text?: string
  label: string
  name?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLButtonElement>
}

function Checkbox(props: CheckboxProps) {
  return (
    <Stack direction='row' alignItems='center'>
      <FormControlLabel control={
        <MuiCheckbox
          checked={props.checked}
          disableRipple
          name={props.name}
          onBlur={props.onBlur}
          onChange={props.onChange}
          size='medium'
          style={{
            height: 40,
            margin: 0,
            padding: props.text && props.text.length > 0 ? '0 15px 0 0' : 0
          }}
        />}
        label={<p style={{ fontSize: '1em', padding: 0, margin: 0, marginLeft: '0.75em', color: 'var(--app-text-dark)' }}>{props.label}</p>}
        style={{ paddingLeft: '15px' }}
      />
    </Stack>
  )
}

export default Checkbox