import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Product } from '../../types'
import { RootState } from '..'

export interface ProductState {
    list: Product[]
}

const initialState: ProductState = {
    list: []
}

//Create slice in App State
export const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        resetProductsSlice: () => initialState,
        setProducts: (state, action: PayloadAction<Product[]>) => {
            state.list = action.payload
        },
    },
})

//Actions
export const { setProducts, resetProductsSlice } = productSlice.actions

//Selectors
export const getProducts = (state: RootState) => state.products.list

//Export Reducer
export default productSlice.reducer