import {
    Box,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material'
import { FC, ReactNode } from 'react'
import { useAppSelector } from '../../store/hooks'
import * as Store from '../../store/slices'

const NavItem: FC<{
    active: boolean,
    icon: ReactNode,
    label: string,
    onClick: () => void
}> = ({ active, icon, label, onClick }) => {
    const collapsed = useAppSelector(Store.getAppBarState)

    return (
        <ListItem
            key={label}
            disablePadding
            sx={{
                display: 'block',
                paddingLeft: collapsed ? '0px' : '1em'
            }}
        >
            <Box
                sx={{
                    backgroundColor: active ? 'var(--ui-sky-blue)' : 'transparent',
                    borderRadius: collapsed ? '0px' : '8px 0px 0px 8px'
                }}
            >
                <ListItemButton
                    onClick={onClick}
                    sx={{
                        color: active ? 'var(--app-primary)' : 'var(--ui-sky-blue)',
                        minHeight: '3em',
                        justifyContent: active ? 'initial' : 'center',
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            color: active ? 'var(--app-primary)' : 'var(--ui-sky-blue)',
                            minWidth: 0,
                            mr: !collapsed ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        {icon}
                    </ListItemIcon>
                    <ListItemText
                        primary={label}
                        sx={{
                            opacity: !collapsed ? 1 : 0
                        }}
                    />
                </ListItemButton>
            </Box>
        </ListItem>
    )
}

export default NavItem