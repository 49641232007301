import { BaseButton } from '../buttons'
import { Formik } from 'formik'
import { BaseInput } from '../inputs'
import { Box, Divider, Stack } from '@mui/material'
import { useMemo } from 'react'
import { useAppSelector } from '../../store/hooks'
import * as Store from '../../store/slices'
import { Product, ProductFormActions } from '../../types'
import { ProductSchema } from '../../types/schemas'
import { ApiUtility, Endpoints } from '../../utilities'

type ProductFormProps = {
    productId?: number
    onSubmit: (action: ProductFormActions, product: Product) => void
}

export default function Form(props: ProductFormProps) {
    let newProduct = { sku: '', description: '', depth: '', width: '', height: '' }
    const products = useAppSelector(Store.getProducts)
    const currentProduct = useMemo(() => products.find(p => p.idProducts === props.productId), [props.productId])
    const submit = async (values: Partial<Product>) => {
        // Edit
        if (props.productId) {
            const response = await ApiUtility.Post(Endpoints.Products, values)
            if (response.success) {
                props.onSubmit('edited', response.data)
                return
            }
            alert('Error while editing product')
            // Create
        } else {
            const response = await ApiUtility.Put(Endpoints.Products, values)
            if (response.success) {
                props.onSubmit('created', response.data)
                return
            }
            alert('Error while creating product')
        }
    }

    const deleteProduct = async () => {
        if (!window.confirm('Are you sure that you want to delete this product? Orders that used this SKU will not be affected and must be updated manually if needed.')) {
            return
        }
        const res = await ApiUtility.Delete(Endpoints.Products, currentProduct)
        if (res.success) {
            props.onSubmit('deleted', currentProduct!)
            return
        }
        alert('Unable to delete the Product.')
    }

    return (
        <Formik
            initialValues={currentProduct || newProduct}
            validationSchema={ProductSchema}
            validateOnMount={true}
            onSubmit={submit}
        >
            {({ values, errors, touched, dirty, isValid, handleBlur, handleChange, handleSubmit, isSubmitting, setValues }) => (
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <BaseInput name='sku' label='SKU Number' value={values.sku} error={touched.sku && errors.sku ? errors.sku : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                    <BaseInput name='description' label='Description' value={values.description} error={touched.description && errors.description ? errors.description : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                    <Stack spacing={2} direction='row'>
                        <BaseInput name='width' label='Width' value={values.width} error={touched.width && errors.width ? errors.width : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                        <BaseInput name='height' label='Height' value={values.height} error={touched.height && errors.height ? errors.height : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                        <BaseInput name='depth' label='Depth' value={values.depth} error={touched.depth && errors.depth ? errors.depth : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                    </Stack>
                    <Box sx={{mb: 2}}>
                        <p className='subtle'>Enter "N/A" in cases where a dimension does not apply.</p>
                    </Box>
                    <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <BaseButton text='Save' disabled={!isValid || !dirty} onClick={handleSubmit} type='submit' rightIcon='arrowRight' loading={isSubmitting} fullWidth />
                    </Box>
                    <Box height={16} />
                    {!!props.productId && (
                        <Stack spacing={2}>
                            <Divider />
                            <h5>Delete Product</h5>
                            <BaseButton text='Delete Product' variant='delete' onClick={() => deleteProduct()} />
                        </Stack>
                    )}
                </form>
            )}
        </Formik>
    )
}