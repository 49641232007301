type ChipButtonProps = {
    onClick: React.MouseEventHandler<HTMLDivElement>
    text: string
}

export default function ChipButton(props: ChipButtonProps) {
    return (
        <div className='chip-button noselect' onClick={props.onClick}>
            <p>{props.text}</p>
        </div>
    )
}