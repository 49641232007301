import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Dealership } from '../../types'
import { RootState } from '..'

export interface DealershipState {
    list: Dealership[]
}

const initialState: DealershipState = {
    list: []
}

//Create slice in App State
export const dealershipSlice = createSlice({
    name: 'dealerships',
    initialState,
    reducers: {
        resetDealershipSlice: () => initialState,
        setDealerships: (state, action: PayloadAction<Dealership[]>) => {
            state.list = action.payload
        },
    },
})

//Actions
export const { setDealerships, resetDealershipSlice } = dealershipSlice.actions

//Selectors
export const getDealerships = (state: RootState) => state.dealerships.list

//Export Reducer
export default dealershipSlice.reducer