import { IconButton, InputAdornment, TextField } from '@mui/material'
import { RequiredIndicator } from '../ui'

interface BaseInputProps {
    autoFocus?: boolean
    characterLimit?: number
    customWidth?: string
    defaultValue?: string
    error?: any
    fullHeight?: boolean
    fullWidth?: boolean
    label?: string
    labelClassName?: string
    labelColor?: string
    name?: string
    onBlur?: React.FocusEventHandler<HTMLInputElement>
    onChange: React.ChangeEventHandler<HTMLInputElement>
    onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>
    icon?: string
    placeholder?: string
    optional?: boolean
    style?: object
    type?: string
    value?: string
    disabled?: boolean
}

function BaseInput(props: BaseInputProps) {
    const tooLong = props.characterLimit && props.characterLimit > 0 && ((props.value && props.value.length > props.characterLimit) || (props.defaultValue && props.defaultValue.length > props.characterLimit))
    const label = (props.label || '') + (props.error ? ': ' + props.error : '') + (tooLong ? ': Too long' : '')

    return (
        <div style={{ display: 'block', marginBottom: '1em', width: props.customWidth || (props.fullWidth ? '100%' : 150) }}>
            {props.label &&
                <div className='input-label' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                    <p className='input-label' style={{ color: props.error || tooLong ? '#FF0000' : 'var(--app-text-dark)' }}>{label}{!props.optional && (<RequiredIndicator />)}</p>
                    {props.characterLimit && <p className='subtle' style={tooLong ? {fontWeight: '500', color: '#FF0000'} : {}}>{props.value?.length || props.defaultValue?.length || '0'} / {props.characterLimit}</p>}
                    {!props.characterLimit && props.optional && <p className='subtle'>Optional</p>}
                </div>
            }
            <TextField
                {...props as Omit<BaseInputProps, 'error'>}
                autoComplete='off'
                autoFocus={props.autoFocus}
                className='mui-input'
                fullWidth={!!props.customWidth || props.fullWidth}
                multiline={props.type === 'textarea'}
                InputProps={{
                    disableUnderline: true,
                    endAdornment: props.icon && (
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='icon'
                                onClick={() => { }}
                            >
                                <img src={props.icon} height='22px' width='22px' alt={props.icon} />
                            </IconButton>
                        </InputAdornment>
                    ),
                    style: {
                        display: 'flex',
                        minHeight: '100%',
                        padding: '5px',
                        alignItems: props.type === 'textarea' ? 'flex-start' : 'center',
                    }
                }}
                label=''
                name={props.name}
                onKeyUp={props.onKeyUp}
                onKeyUpCapture={props.onKeyUp}
                placeholder={props.placeholder || ''}
                size='medium'
                style={{
                    border: '1px solid var(--ui-light-blue)',
                    borderRadius: '0.5em',
                    minHeight: props.type === 'textarea' ? '6em' : '2.5em',
                    height: props.type === 'textarea' ? '100%' : '2.5em',
                    backgroundColor: 'white'
                }}
                type={props.type || 'text'}
                variant='standard'
            />
            {!props.label && props.characterLimit && 
                <div className='input-label' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                    {props.characterLimit && <p className='subtle'>{props.value?.length} / {props.characterLimit}</p>}
                </div>
            }
        </div>
    )
}

export default BaseInput