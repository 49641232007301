import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import * as Store from '../store/slices'
import { BaseButton } from '../components/buttons'
import { BaseDialog } from '../components/dialogs'
import { BaseGrid, BasePage, ManageInvitationMenu, ManageUserMenu } from '../components/layout'
import { Invitation, User } from '../types'
import { ApiUtility, Endpoints, FormattingUtility } from '../utilities'
import { GridColDef } from '@mui/x-data-grid'
import { UserRoles } from '../types/enums'
import { CreateUser } from '../components/forms'

const UsersColumnDefinitions: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 1, valueGetter: ({ row }) => row.firstName + ' ' + row.lastName },
    { field: 'email', headerName: 'Email', flex: 2, },
    { field: 'role', headerName: 'User Type', flex: 1, valueGetter: ({ row }) => FormattingUtility.getUserRole(row.role) },
    { field: 'idDealerships', headerName: 'Dealership', flex: 1, valueGetter: ({ row }) => (row.dealership && row.dealership.name) || '' },
    { field: 'created', headerName: 'Created', flex: 1, valueGetter: ({ row }) => FormattingUtility.formatISODate(row.created) },
    {
        field: '',
        headerName: '',
        sortable: false,
        width: 30,
        renderCell: ({ row }) => (
            <span className='row flex-center' style={{ width: '100%' }}>
                <ManageUserMenu user={row} />
            </span>
        )
    },
]

export default function Page() {
    const dispatch = useAppDispatch()
    const currentUser = useAppSelector<User | null>(Store.getCurrentUser)
    const users = useAppSelector<User[]>(Store.getUsers)
    const invitations = useAppSelector<Invitation[]>(Store.getInvitations)
    const [isInviteUserOpen, toggleInviteUser] = useState<boolean>(false)
    const AllowedRoles = [UserRoles.Admin, UserRoles.Accountant, UserRoles.CustomerService, UserRoles.FieldRep]

    const InvitationsColumnDefinitions: GridColDef[] = [
        { field: 'name', headerName: 'Name', flex: 1, valueGetter: ({ row }) => row.firstName + ' ' + row.lastName },
        { field: 'email', headerName: 'Email', flex: 2, },
        { field: 'role', headerName: 'User Type', flex: 1, valueGetter: ({ row }) => FormattingUtility.getUserRole(row.role) },
        { field: 'idDealerships', headerName: 'Dealership', flex: 1, valueGetter: ({ row }) => (row.dealership && row.dealership.name) || '' },
        { field: 'modified', headerName: 'Invited', flex: 1, valueGetter: ({ row }) => FormattingUtility.formatISODate(row.modified) },
        {
            field: '',
            headerName: '',
            sortable: false,
            width: 30,
            renderCell: ({ row }) => (
                <span className='row flex-center' style={{ width: '100%' }}>
                    <ManageInvitationMenu invitation={row} />
                </span>
            )
        },
    ]

    const loadUsers = async (force: boolean = false) => {
        if (!force && users && users.length > 0) {
            return
        }

        let response = await ApiUtility.Get(Endpoints.Users)
        if (!response.success) {
            return
        }

        dispatch(Store.setUsers(response.data))
    }

    const loadInvites = async () => {
        let response = await ApiUtility.Get(Endpoints.InvitedUsers)
        if (!response.success) {
            return
        }

        dispatch(Store.setInvitations(response.data))
    }

    useEffect(() => {
        setTimeout(() => {
            loadUsers(true)
            loadInvites()
        }, 20)
    }, [])

    return (
        <BasePage title='Users' requireAuth={true}>
            <div className='ui-row'>
                <div className='dashboard-card' style={{ width: '100%' }}>
                    <div className='row' style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: '1em' }}>
                        <p className='card-header'>Active users</p>
                        {/* TODO: Search bar */}
                    </div>
                    <BaseGrid
                        columns={UsersColumnDefinitions}
                        data={users}
                        idKey='authId'
                    />
                </div>
            </div>
            <div className='ui-row'>
                <div className='dashboard-card' style={{ width: '100%' }}>
                    <div className='row' style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: '1em' }}>
                        <p className='card-header'>Pending invitations</p>
                        <BaseButton text='Invite MA User' onClick={() => toggleInviteUser(true)} leftIcon='add' />
                    </div>
                    {invitations &&
                        <BaseGrid
                            columns={InvitationsColumnDefinitions}
                            data={invitations}
                            idKey='idInvitations'
                        />
                    }
                </div>
            </div>
            <BaseDialog
                isOpen={isInviteUserOpen}
                cancellable={true}
                onClose={() => toggleInviteUser(false)}
                heading={'Invite User'}
            >
                <div style={{ maxHeight: '550px' }}>
                    <p className='subtle' style={{ marginBottom: '1em' }}>Complete all fields and click Submit at the bottom.</p>
                    <CreateUser
                        onSubmit={() => { toggleInviteUser(false); loadUsers(true); loadInvites(); }}
                        roles={AllowedRoles}
                    />
                </div>
            </BaseDialog>
        </BasePage>
    )
}