import { useState } from 'react'
import { BaseButton } from '../buttons'
import { BaseDialog } from '../dialogs'
import { S3FileInfo } from '../../types'
import { ApiUtility, Endpoints } from '../../utilities'
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded'
import DeleteForeverRounded from '@mui/icons-material/DeleteForeverRounded'
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded'
import { IconButton } from '@mui/material'

type FileRowProps = {
    file: S3FileInfo
    onClick: () => void
    onDelete: false | (() => void)
}

export default function FileRow(props: FileRowProps) {
    const [confirm, toggleConfirm] = useState<boolean>(false)
    const [deleting, toggleDeleting] = useState<boolean>(false)

    const deleteFile = async () => {
        toggleDeleting(true)

        setTimeout(async () => {
            if (!props.onDelete) {
                toggleDeleting(false)
                return
            }

            const response = await ApiUtility.Delete(Endpoints.Storage, { key: props.file.key })

            if (response.success) {
                props.onDelete()
            } else {
                toggleDeleting(false)
                alert('There was an unknown error. Please try again.')
            }
        }, 1500)
    }

    return (
        <>
            <div className='file-row' onClick={props.onClick}>
                <div className='row'>
                    <AttachFileRoundedIcon style={{ color: 'var(--ui-dark-blue)', marginRight: '0.2em' }} />
                    <p>{props.file.name}</p>
                </div>
                {props.onDelete && (
                    <IconButton onClick={(e) => { e.stopPropagation(); toggleConfirm(true); }}>
                        <DeleteForeverRounded />
                    </IconButton>
                )}
                {!props.onDelete && (
                    <IconButton disableRipple onClick={props.onClick}>
                        <FileDownloadRoundedIcon />
                    </IconButton>
                )}
            </div>
            <BaseDialog isOpen={confirm} cancellable={true} heading='Delete File?' onClose={() => toggleConfirm(false)}>
                <p>Please confirm that you would like to delete this file:</p>
                <p>{props.file.name}</p>
                <div className='row' style={{ justifyContent: 'flex-end' }}>
                    <BaseButton text='Confirm' onClick={deleteFile} variant='delete' loading={deleting} />
                </div>
            </BaseDialog>
        </>
    )
}