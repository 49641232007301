import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { urls } from '../../pages'
import * as Store from '../../store/slices'
import { BaseButton } from '../buttons'
import { BaseInput, PhoneInput } from '../inputs'
import { Card, Row, Table } from '../layout'
import { Dealership } from '../../types'
import { DealershipSchema, LocationSchema } from '../../types/schemas'
import { ProgressIndicator } from '../ui'
import { ApiUtility, Endpoints, FormattingUtility } from '../../utilities'
import { IconButton } from '@mui/material'
import { Formik } from 'formik'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import RotateLeftRoundedIcon from '@mui/icons-material/RotateLeftRounded'
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded'

enum UIState {
    DealershipInfo = 1,
    LocationInfo = 2,
    Review = 3,
    Submitted = 4,
}

type WizardProps = {
    onClose: () => void
}

type NavProps = {
    enableNext: boolean
    enablePrevious: boolean
    onCancel: () => void
    onNext: () => void
    onPrevious: () => void
    onSubmit: () => void
    showSubmit: boolean
}

const __blank_add_location__ = {
    address1: '',
    address2: '',
    city: '',
    faxNumber: '',
    name: '',
    phoneNumber: '',
    state: '',
    zip: '',
}

const WizardNav = (props: NavProps) => {
    const [loading, toggleLoading] = useState<boolean>(false)
    const _submit = async () => {
        toggleLoading(true)
        await props.onSubmit()
        toggleLoading(false)
    }
    return (
        <span>
            <BaseButton
                disabled={loading}
                leftIcon='x'
                onClick={props.onCancel}
                style={{ marginRight: '1em' }}
                text='Cancel'
                variant='delete'
            />
            <BaseButton
                text='Previous'
                disabled={!props.enablePrevious || loading}
                leftIcon='arrowLeft'
                onClick={props.onPrevious}
                style={{ marginRight: '1em' }}
                type='submit'
                variant='tertiary'
            />
            {!props.showSubmit && (
                <BaseButton
                    text='Proceed'
                    disabled={!props.enableNext}
                    rightIcon='arrowRight'
                    onClick={props.onNext}
                    variant='tertiary'
                />
            )}
            {props.showSubmit && (
                <BaseButton
                    text='Submit'
                    rightIcon='arrowRight'
                    loading={loading}
                    onClick={_submit}
                    variant='tertiary'
                />
            )}
        </span>
    )
}

export default function CreateDealershipWizard(props: WizardProps) {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const dealerships = useAppSelector<Dealership[]>(Store.getDealerships)
    const [state, setState] = useState<UIState>(UIState.DealershipInfo)
    const [addDealership, setAddDealership] = useState<Partial<Dealership> | null>(null)
    const [addLocation, setAddLocation] = useState<any>(null);
    const [locations, setLocations] = useState<any[]>([])

    const confirmCancel = () => {
        if (!window.confirm('Are you sure that you want to discard your changes?')) {
            return
        }

        props.onClose()
    }

    const submit = async () => {
        if (!window.confirm('Are you sure that you want to create this dealership?')) {
            return
        }

        let dealership = await submitDealership()

        if (!dealership) {
            alert('There was an unexpected error while trying to create this dealership. Please contact an Admin for assistance.')
            return
        }

        // Add each location
        for (let location of locations) {
            // Create Location
            const locResponse = await ApiUtility.Put(Endpoints.Locations, { ...location, idDealerships: dealership.idDealerships })
            if (!locResponse.success) {
                alert(`There was an unexpected error while trying to add a location (${location.name}). Please contact an Admin for assistance.`)
                return
            }
        }

        setState(UIState.Submitted)
        dispatch(Store.setDealerships([...dealerships, dealership]))
    }

    const submitDealership = async (): Promise<Dealership | null> => {
        // 0. Validate
        if (!addDealership) {
            return null
        }

        // 0. Create Dealership
        let response = await ApiUtility.Put(Endpoints.Dealerships, addDealership)

        if (!response.success) {
            let message = ''
            switch (response.statusCode) {
                case 409:
                    message = 'A dealership with that name already exists.'
                    break
                default:
                    message = 'There was a problem adding the dealership.'
                    break
            }
            alert(message)
            return null
        }

        return response.data
    }

    return (
        <>
            {state === UIState.DealershipInfo && (
                <Formik
                    initialValues={addDealership || {
                        businessType: '',
                        faxNumber: '',
                        name: '',
                        phoneNumber: '',
                    }}
                    validateOnMount={true}
                    validationSchema={DealershipSchema}
                    onSubmit={values => { setAddDealership(values); setState(state + 1); }}
                >
                    {({ values, touched, errors, isValid, handleSubmit, handleBlur, handleChange }) => (
                        <>
                            <div className='ui-row' style={{ justifyContent: 'space-between' }}>
                                <p className='wizard-instructions'>Follow the steps below to create a new dealer account with Modern Amish. You will be able to add additional locations once the dealership is created.</p>
                                <WizardNav
                                    enableNext={isValid}
                                    enablePrevious={state !== UIState.DealershipInfo}
                                    onCancel={confirmCancel}
                                    onNext={handleSubmit}
                                    onPrevious={() => setState(state - 1)}
                                    onSubmit={submit}
                                    showSubmit={false}
                                />
                            </div>
                            <div className='ui-row'>
                                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                    <div className='row' style={{ marginBottom: '1em' }}>
                                        <div className='dashboard-card' style={{ width: '500px', marginRight: '1em', marginBottom: '1em' }}>
                                            <BaseInput name='name' label='Business Name' defaultValue={values.name} error={touched.name && errors.name ? errors.name : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                            <BaseInput name='businessType' label='Business Type' defaultValue={values.businessType} error={touched.businessType && errors.businessType ? errors.businessType : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                            <PhoneInput name='phoneNumber' label='Phone Number' defaultValue={values.phoneNumber} error={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                            <PhoneInput name='faxNumber' label='Fax Number' optional defaultValue={values.faxNumber} error={touched.faxNumber && errors.faxNumber ? errors.faxNumber : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </>
                    )}
                </Formik>
            )}
            {state === UIState.LocationInfo && (
                <Formik
                    initialValues={addLocation || __blank_add_location__}
                    validationSchema={LocationSchema}
                    enableReinitialize={true}
                    onSubmit={values => { setAddLocation(null); setState(state + 1) }}
                >
                    {({ values, touched, errors, dirty, isValid, handleSubmit, handleBlur, handleChange, resetForm }) => (
                        <>
                            <div className='ui-row' style={{ justifyContent: 'space-between' }}>
                                <p className='wizard-instructions'>Use the form below to add the initial location for this Dealership. Additional locations can be added as part of this process, later from the Dealership detail view, or delegated to the Dealer user.</p>
                                <WizardNav
                                    enableNext={locations.length > 0}
                                    enablePrevious={true}
                                    onCancel={confirmCancel}
                                    onNext={() => setState(state + 1)}
                                    onPrevious={() => setState(state - 1)}
                                    onSubmit={submit}
                                    showSubmit={false}
                                />
                            </div>
                            <div className='ui-row grid-container'>
                                <div className='grid'>
                                    <Card
                                        title='New Location'
                                        rightHeader={<IconButton onClick={() => { resetForm({ values: null }); setAddLocation(null); }}><RotateLeftRoundedIcon /></IconButton>}
                                    >
                                        <form onSubmit={handleSubmit}>
                                            <BaseInput name='name' label='Name' value={values.name} error={touched.name && errors.name ? errors.name : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                            <PhoneInput name='phoneNumber' label='Phone Number' defaultValue={values.phoneNumber} error={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                            <PhoneInput name='faxNumber' label='Fax Number' optional defaultValue={values.faxNumber} error={touched.faxNumber && errors.faxNumber ? errors.faxNumber : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                            <div style={{ width: '100%', height: '2px', backgroundColor: 'var(--ui-dark-grey)', margin: '2em 0em' }}></div>
                                            <BaseInput name='address1' label='Address 1' value={values.address1} error={touched.address1 && errors.address1 ? errors.address1 : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                            <BaseInput name='address2' label='Address 2' optional value={values.address2} error={touched.address2 && errors.address2 ? errors.address2 : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                            <BaseInput name='city' label='City' value={values.city} error={touched.city && errors.city ? errors.city : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                            <BaseInput name='state' label='State' value={values.state} error={touched.state && errors.state ? errors.state : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                            <BaseInput name='zip' label='Zip' value={values.zip} error={touched.zip && errors.zip ? errors.zip : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                            <BaseButton text='Save Location' rightIcon='arrowRight' disabled={!isValid} onClick={() => { setLocations([...locations, values]); resetForm(); }} />
                                        </form>
                                    </Card>
                                    <div>
                                        {locations && locations.map(el => (
                                            <Card
                                                key={el.name}
                                                title={el.name}
                                                rightHeader={
                                                    <>
                                                        <IconButton onClick={() => { setAddLocation(el); setLocations(prev => prev.filter(l => l.name !== el.name)) }}><EditRoundedIcon /></IconButton>
                                                        <IconButton onClick={() => setLocations(prev => prev.filter(l => l.name !== el.name))}><DeleteForeverRoundedIcon /></IconButton>
                                                    </>
                                                }
                                            >
                                                <Table>
                                                    <Row label='Address' value={FormattingUtility.FormatAddressParts({ address1: el.address1, address2: el.address2 })} />
                                                    <Row label='' value={FormattingUtility.FormatAddressParts({ city: el.city, state: el.state, zip: el.zip })} />
                                                    <Row blank />
                                                    <Row label='Phone' value={FormattingUtility.formatPhoneNumber(el.phoneNumber)} />
                                                    <Row label='Fax' value={FormattingUtility.formatPhoneNumber(el.faxNumber)} />
                                                </Table>
                                            </Card>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </Formik>
            )}
            {state === UIState.Review && (
                <>
                    <div className='ui-row' style={{ justifyContent: 'space-between' }}>
                        <p className='wizard-instructions'>Please review the information below. Once you click submit, the dealership will be added to the system, and any invitations will be sent out.</p>
                        <WizardNav
                            enableNext={false}
                            enablePrevious={true}
                            onCancel={confirmCancel}
                            onNext={() => { }}
                            onPrevious={() => setState(state - 1)}
                            onSubmit={submit}
                            showSubmit={true}
                        />
                    </div>
                    <div className='ui-row' style={{ width: '600px' }}>
                        <Card title='Dealership Information' fullWidth>
                            <Table>
                                <Row label='Name' value={addDealership?.name} />
                                <Row label='Type' value={addDealership?.businessType} />
                                <Row label='Phone' value={FormattingUtility.formatPhoneNumber(addDealership?.phoneNumber)} />
                                <Row label='Fax' value={FormattingUtility.formatPhoneNumber(addDealership?.faxNumber)} />
                            </Table>
                        </Card>
                    </div>
                    <div className='ui-row' style={{ width: '600px' }}>
                        <Card title='Locations' fullWidth>
                            {locations && locations.map((el, i) => (
                                <p key={el.name}>{i + 1}. {el.name}</p>
                            ))}
                        </Card>
                    </div>
                </>
            )}
            {state !== UIState.Submitted && (
                <div className='ui-row'>
                    <ProgressIndicator current={state} total={3} />
                </div>
            )}
            {state === UIState.Submitted && (
                <div style={{ height: '100%', width: '100%', flex: 1, display: 'flex', }} className='column flex-center'>
                    <div className='dashboard-card flex-center' style={{ padding: '2em', width: '450px' }}>
                        <VerifiedRoundedIcon style={{ height: '5em', width: '5em', color: 'var(--app-tertiary)', marginBottom: '1em' }} />
                        <h4 style={{ marginBottom: '1em' }}>Dealership successfully created!</h4>
                        <p style={{ textAlign: 'center', marginBottom: '2em' }}>Invitations for the Dealer user and any other users of the platform can be sent from this Dealerships details page.</p>
                        <BaseButton text='Return to Dashboard' style={{ width: '300px' }} rounded onClick={() => history.push(urls.dashboard)} />
                    </div>
                </div>
            )}
        </>
    )
}